<script>
import { VSkeletonLoader } from "vuetify/lib";
export default {
  name: "WxSkeletonLoader",
  extends: VSkeletonLoader,
};
</script>

<style lang="scss" scoped>
.v-skeleton-loader {
  width: 100%;
  height: 100%;
  border-radius: var(--border-radius-lg);
}
.v-skeleton-loader__image {
  min-height: 300px;
  height: 100% !important;
}
</style>