<template>
  <v-card
    :wxid="$options.name"
    :class="{ disabled: isTileDisabled, unfocused: isTileUnfocused }"
    class="tile work-order"
    tag="section"
    sf="1"
  >
    <grouped-tile-selector-menu :tile-index="tileIndex" />
    <div class="tile-inner">
      <h3 :title="$t('tiles.workOrder.name')" class="primary-title wo-number">
        {{ $t("tiles.workOrder.name") }}
        <v-icon v-if="!isTileAuthorized()">mdi-lock</v-icon>
      </h3>

      <fragment v-if="isWorkOrderInProgress">
        <h2 class="primary-value">{{ primaryValue }}</h2>
        <p class="value-sub-text__unit count">
          {{ secondaryValue }}
          {{ unitName }}
        </p>
        <section class="status-details">
          <dl>
            <dt>{{ $t("tiles.plannedQuantity") }}</dt>
            <dd>
              {{ plannedQuantity }}
            </dd>
          </dl>
        </section>
      </fragment>
      <!-- if !isWorkOrderInProgress -->
      <fragment v-else>
        <h2 class="primary-value wrap">{{ nullCharacter }}</h2>
        <p class="value-sub-text__unit null">{{ $t("common.none") }}</p>
      </fragment>
    </div>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import ProductionUnitService from "@/components/productionunit/ProductionUnitService";
import GroupedTileSelectorMenu from "@/components/dashboard/tiles/GroupedTileSelectorMenu";
import TileBase from "@/components/dashboard/tiles/TileBase";
import Tiles from "@/components/Tiles";
import Helpers from "@/helpers";

export default {
  name: "TileWorkOrder",
  extends: TileBase,
  inheritAttrs: false,
  components: { GroupedTileSelectorMenu },
  data() {
    return {
      nullCharacter: "—",
    };
  },
  computed: {
    ...mapGetters("dashboard", ["currentWorkOrder", "productionUnitConvertedUnit"]),
    ...mapGetters("packages", ["activePuHasRequiredFeature"]),
    ...mapGetters("tiles", ["selectedTileIndex"]),
    isTileUnfocused() {
      return this.selectedTileIndex !== null && this.tileIndex !== this.selectedTileIndex;
    },
    isTileDisabled() {
      return !this.isTileAuthorized();
    },
    isWorkOrderInProgress() {
      return this.currentWorkOrder !== null;
    },
    isPlannedQuantity() {
      return (
        this.currentWorkOrder !== null &&
        this.currentWorkOrder.planned_quantity !== null &&
        this.currentWorkOrder.planned_quantity > 0
      );
    },
    primaryValue() {
      const currentQuantity = this.currentWorkOrder ? this.currentWorkOrder.quantity : 0;
      const currentPlannedQuantity = this.currentWorkOrder ? this.currentWorkOrder.planned_quantity : 0;
      if (currentPlannedQuantity && currentPlannedQuantity > 0) {
        return Helpers.round(100 * (currentQuantity / currentPlannedQuantity), 1) + "%";
      } else {
        return "-";
      }
    },
    secondaryValue() {
      if (this.currentWorkOrder) {
        return this.currentWorkOrder.quantity ? this.currentWorkOrder.quantity : 0;
      }
      return 0;
    },
    unitName() {
      if (this.productionUnitConvertedUnit && this.isTileAuthorized()) {
        return ProductionUnitService.getUnitName(this.productionUnitConvertedUnit);
      } else {
        return "";
      }
    },
    plannedQuantity() {
      return this.currentWorkOrder.planned_quantity ? this.currentWorkOrder.planned_quantity : "—";
    },
  },
  methods: {
    isTileAuthorized() {
      return this.activePuHasRequiredFeature(Tiles.workOrder.requiredFeature);
    },
  },
};
</script>

<style lang="scss">
.wo-number {
  display: inline-block;
  width: calc(100% - 40px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.wrap {
  overflow-wrap: normal;
}
</style>
