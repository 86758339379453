import store from "../store";

class Accessibility {
  addPalette(cssClass) {
    let isAccessible = store.getters["user/isAccessible"];
    let palette = isAccessible ? `-accessible` : "";
    return `${cssClass}${palette}`;
  }
}

export default new Accessibility();
