<!--
  Prop for optional slot:
    * is-display-optional-column : <template v-slot:optional-column-container>

  Optional props for inline CSS:
    * optional-col-class=""
    * first-col-class=""
    * row-vertical-spacing-class=""
-->
<template>
  <v-row class="align-center flex-small-column-gap">
    <v-col cols="12" sm="auto">
      <wx-select
        v-model="selectedTimeFrame"
        :items="timeFrameItems"
        :title="$t('timeNavigator.selectTimeFrame_hint')"
        :dense="false"
        class="filter-style"
        hide-details
        offset-y
      />
    </v-col>
    <v-col cols="12" sm="auto" class="d-flex justify-center" v-if="showNavigation">
      <v-menu
        v-model="isDatePickerOpen"
        :close-on-content-click="false"
        transition="slide-y-transition"
        max-width="350px"
        offset-y
        left
        nudge-bottom="4"
        rounded="lg"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-on="on"
            v-bind="attrs"
            text
            large
            class="font-weight-regular text-body-1"
            :title="$t('datePickerByTimeframe.changeDate')"
          >
            <v-icon class="mr-3">$calendarItemIcon</v-icon>
            {{ dateLabel }}
          </v-btn>
        </template>
        <v-card :wxid="$options.name" tag="article" class="wx-panel" rounded>
          <v-row>
            <v-col class="second-column pt-sm-5 d-flex flex-column">
              <v-date-picker
                v-model="selectedDate"
                :min="getMinDate()"
                :max="getMaxDate()"
                :locale="datePickerLocale"
                color="primary"
                width="100%"
                show-adjacent-months
                no-title
                flat
              />
              <wx-btn-standard
                @click="onSelectedDate"
                :title="$t('dateRangePicker.applyDates')"
                color="primary"
                class="submit-btn flex-grow-1"
              >
                {{ $t("dateRangePicker.applyDates") }}
              </wx-btn-standard>
            </v-col>
          </v-row>
        </v-card>
      </v-menu>
    </v-col>
    <v-col cols="12" sm="auto" class="d-flex justify-center" v-if="showNavigation">
      <wx-btn-icon
        @click="selectPreviousTimeFrame"
        :title="$t('common.previous')"
        :disabled="isPreviousButtonDisabled"
        style="height: 39px"
        text
      >
        <v-icon>mdi-chevron-left</v-icon>
      </wx-btn-icon>
      <wx-btn-standard
        @click="goToPresent()"
        :title="$t('timeNavigator.presentBtn')"
        :disabled="isPresentButtonDisabled"
        class="mx-1"
        style="height: 39px"
        text
      >
        {{ $t("timeNavigator.presentBtn") }}
      </wx-btn-standard>
      <wx-btn-icon
        @click="selectNextTimeFrame"
        :title="$t('common.next')"
        :disabled="isNextButtonDisabled"
        style="height: 39px"
        text
      >
        <v-icon>mdi-chevron-right</v-icon>
      </wx-btn-icon>
    </v-col>
    <!-- add isOverview check since we don't handle logic if all current shifts are the same or different yet so we show no work shift name-->
    <v-col cols="12" sm="auto" class="d-flex justify-center" v-if="showPeriodName">
      <div class="d-flex align-center" v-if="selectedTimeFrame === 'shift' && workShiftCoverage">
        <!--
          Designer requested to truncate after un max of
          20 characters. I added class `text-truncate` to
          prevent minor issues on smaller breakpoints.
          - Martin Dube
          -->
        <wx-contextualized-help
          v-if="isWorkShiftNameTruncated"
          :help-card-title="$t('timeNavigator.workShiftName')"
          :help-card-activator-text="truncatedWorkShiftName"
          :max-width="320"
          color-inverted-theme-as-tooltips
          is-help-card
        >
          <template v-slot:help-card-text-slot>
            <span>
              {{ workShiftName }}
            </span>
          </template>
        </wx-contextualized-help>
        <span
          v-else
          :title="$t('timeNavigator.workShiftName')"
          class="timeframe--aside__shift-name cursor-help text-truncate"
        >
          {{ workShiftName }}
        </span>
      </div>
      <div class="d-flex align-center" v-if="selectedTimeFrame === 'hour'">
        <span class="timeframe--aside__shift-name cursor-help text-truncate">
          {{ selectedHourLabel }}
        </span>
      </div>
    </v-col>
    <v-col v-if="!!$slots['tag-selector']" cols="12" sm="auto" class="d-flex justify-center">
      <slot name="tag-selector" />
    </v-col>
    <v-col v-if="!!$slots['optional-column-container']" class="d-flex flex-small-column-gap justify-end">
      <slot name="optional-column-container" />
    </v-col>
  </v-row>
</template>

<script>
import WxBtnStandard from "@/components/ui/WxBtnStandard.vue";
import WxBtnIcon from "@/components/ui/WxBtnIcon.vue";
import WxSelect from "@/components/ui/WxSelect.vue";
import { mapActions, mapGetters } from "vuex";
import * as TimeUtils from "@/store/TimeUtils";
import { HOUR, PRODUCTION_DAY, PRODUCTION_RUN, SHIFT } from "@/store/TimeUtils";
import { DateTime } from "luxon";
import HourTimeFrameController from "@/components/ui/timeframedatepicker/HourTimeFrameController";
import ShiftTimeFrameController from "@/components/ui/timeframedatepicker/ShiftTimeFrameController";
import DayTimeFrameController from "@/components/ui/timeframedatepicker/DayTimeFrameController";
import RunTimeFrameController from "@/components/ui/timeframedatepicker/RunTimeFrameController";
import WxContextualizedHelp from "@/components/ui/WxContextualizedHelp.vue";

const DATE_TIME_INTERVAL = 10000;

export default {
  name: "WxTimeFrameDatePicker",
  components: { WxContextualizedHelp, WxSelect, WxBtnIcon, WxBtnStandard },
  props: {
    /**
     * Work shift and production run coverage should only be passed in the dashboard.
     * If nothing is passed, it will disable the navigation by production run and work shift.
     * AKA. The overview will only show the current production run or current work shift but won't
     * be able to navigate to previous production runs or previous work shifts.
     */
    workShiftCoverage: {
      type: Array,
      default: () => null,
    },
    productionRunCoverage: {
      type: Array,
      default: () => null,
    },
    isDisplayOptionalColumn: {
      type: Boolean,
      default: false,
    },
    optionalColClass: {
      type: String,
      default: "d-flex align-center justify-end flex-small-column-gap",
    },
    firstColClass: {
      type: String,
      default: null,
    },
    rowVerticalSpacingClass: {
      type: String,
      default: "mb-3 mb-md-0",
    },
    maxPastMonths: {
      type: Number,
      default: 1,
    },
    /**
     *  If this value is true, the PRODUCTION RUN value will be omitted from the select dropdown.
     * If PRODUCTION RUN is selected in the dashboard, and the user switches to the overview, then
     * the date picker will switch to PRODUCTION DAY and select the start date of the shift or production run.
     *
     * If the CURRENT SHIFT is selected in the dashboard and the user switches to the overview, then it go live with current day.
     *
     * TODO: Remove once SHIFT and PRODUCTION RUN is supported in the overview (with navigation)
     */
    isOverview: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // value that controls the date picker
      isDatePickerOpen: false,

      // properties depending on the selected time frame
      selectedTimeFrame: null,
      selectedDateTime: null,
      selectedHour: null,
      selectedShiftIndex: 0,
      selectedRunIndex: 0,

      // interval that check if the time frame view needs to be switched
      dateTimeInterval: null,

      // time frame controllers
      hourTimeFrameController: new HourTimeFrameController(this),
      shiftTimeFrameController: new ShiftTimeFrameController(this),
      dayTimeFrameController: new DayTimeFrameController(this),
      runTimeFrameController: new RunTimeFrameController(this),
    };
  },
  watch: {
    selectedTimeFrame(newVal, oldVal) {
      if (!oldVal) return;
      this.updateSelectedPeriod(this.selectedTimeFrame);
      this.selectedTimeFrameController.onSelectedTimeFrame();
      this.resetLoaders();
    },
    selectedWorkShift(newWs, oldWs) {
      if (this.selectedTimeFrame === SHIFT) {
        // set the date time to the end of the work shift when one is selected
        // if end is null (current shift) set the current date
        if (!this.selectedWorkShift && !this.isOverview) return;
        if (this.isOverview) return this.dayTimeFrameController.selectNow();
        if (oldWs && this.isObjectEquals(newWs, oldWs)) return;
        const endDate = this.selectedWorkShift.end_date;
        const dateTime = endDate
          ? DateTime.fromISO(endDate, { zone: this.timezone })
          : DateTime.now().setZone(this.timezone);
        this.selectedDateTime = TimeUtils.getBusinessDateTime(
          dateTime,
          this.productionDayMinutesFromMidnight,
          this.isCalendarDayBusinessDay,
        );
        this.submit();
      }
    },
    selectedProductionRun(newPr, oldPr) {
      if (this.selectedTimeFrame === PRODUCTION_RUN) {
        // set the date time to the end of the production run when one is selected
        // if end is null (current production run) set the current date
        if (!this.selectedProductionRun) return;
        if (oldPr && this.isObjectEquals(newPr, oldPr)) return;
        const endDate = this.selectedProductionRun.end_date;
        const dateTime = endDate
          ? DateTime.fromISO(endDate, { zone: this.timezone })
          : DateTime.now().setZone(this.timezone);
        this.selectedDateTime = TimeUtils.getBusinessDateTime(
          dateTime,
          this.productionDayMinutesFromMidnight,
          this.isCalendarDayBusinessDay,
        );
        this.submit();
      }
    },
    isLiveData() {
      if (this.isLiveData) {
        this.dateTimeInterval = setInterval(this.checkDateTime, DATE_TIME_INTERVAL);
      } else {
        clearInterval(this.dateTimeInterval);
      }
    },
    activeFactory() {
      this.initializeData();
    },
    selectedShiftIndex() {
      if (this.isOverview) {
        return this.dayTimeFrameController.selectNow();
      }
    },
    isDatePickerOpen() {
      if (!this.isDatePickerOpen) {
        this.onDatePickerClose();
      }
    },
  },
  computed: {
    ...mapGetters("user", ["language", "isSimplifiedChinese"]),
    ...mapGetters("navigation", ["timeFrame", "date", "dateData", "isLiveData", "activeFactory"]),
    selectedTimeFrameController() {
      switch (this.selectedTimeFrame) {
        case HOUR:
          return this.hourTimeFrameController;
        case SHIFT:
          return this.shiftTimeFrameController;
        case PRODUCTION_DAY:
          return this.dayTimeFrameController;
        case PRODUCTION_RUN:
          return this.runTimeFrameController;
        default:
          // default view is shift mode
          return this.shiftTimeFrameController;
      }
    },
    timezone() {
      return this.activeFactory?.timezone;
    },
    isPresentButtonDisabled() {
      return this.selectedTimeFrameController.isPresentButtonDisabled();
    },
    productionDayMinutesFromMidnight() {
      return this.activeFactory?.productionDayMinutesFromMidnight;
    },
    isCalendarDayBusinessDay() {
      return this.activeFactory?.isCalendarDayBusinessDay;
    },
    selectedDate: {
      get() {
        return this.selectedDateTime?.toFormat(TimeUtils.DATE_FORMAT);
      },
      set(value) {
        this.selectedDateTime = DateTime.fromFormat(value, TimeUtils.DATE_FORMAT, { zone: this.timezone });
      },
    },
    selectedWorkShift() {
      if (!this.workShiftCoverage || this.isOverview) return null;
      return this.workShiftCoverage[this.selectedShiftIndex];
    },
    selectedProductionRun() {
      if (!this.productionRunCoverage || this.selectedRunIndex === null) return null;
      return this.productionRunCoverage[this.selectedRunIndex];
    },
    datePickerLocale() {
      switch (this.language) {
        case "fr":
          return "fr-CA";
        case "es":
          return "es-US";
        case "zh":
          return "zh-CN";
        default:
          return "en-US";
      }
    },
    timeFrameItems() {
      return this.isOverview
        ? [
            { text: this.$t("timeNavigator.timeFrameOption.hour"), value: HOUR },
            { text: this.$t("timeNavigator.timeFrameOption.currentShift"), value: SHIFT },
            { text: this.$t("timeNavigator.timeFrameOption.productionDay"), value: PRODUCTION_DAY },
          ]
        : [
            { text: this.$t("timeNavigator.timeFrameOption.hour"), value: HOUR },
            { text: this.$t("timeNavigator.timeFrameOption.shift"), value: SHIFT },
            { text: this.$t("timeNavigator.timeFrameOption.productionDay"), value: PRODUCTION_DAY },
            { text: this.$t("timeNavigator.timeFrameOption.productionRun"), value: PRODUCTION_RUN },
          ];
    },
    isNextButtonDisabled() {
      return this.selectedTimeFrameController.isNextTimeFrameDisabled();
    },
    isPreviousButtonDisabled() {
      return this.selectedTimeFrameController.isPreviousTimeFrameDisabled();
    },
    showNavigation() {
      return this.selectedTimeFrameController.showNavigation();
    },
    showPeriodName() {
      return this.selectedTimeFrameController.showPeriodName();
    },
    /*******************************
     * Work Shift Label
     *******************************/
    workShiftName() {
      if (this.selectedTimeFrame !== SHIFT || !this.workShiftCoverage) return null;
      if (this.workShiftCoverage && this.workShiftCoverage[this.selectedShiftIndex]) {
        return this.workShiftCoverage[this.selectedShiftIndex].work_shift_name ? this.workShiftCoverage[this.selectedShiftIndex].work_shift_name : this.$t("timeNavigator.outOfProduction");
      } else {
        return null;
      }
    },
    truncatedWorkShiftName() {
      return this.workShiftName ? this.workShiftName.slice(0, 19) + "..." : null;
    },
    isWorkShiftNameTruncated() {
      return this.workShiftName?.length > 20;
    },
    /*******************************
     * Date Label
     *******************************/
    dateLabel() {
      return this.selectedTimeFrameController.getDateLabel();
    },
    coverageOverlappingInfo() {
      let coverageStartDate = null;
      let coverageEndDate = null;
      if (this.selectedTimeFrame === PRODUCTION_RUN) {
        if (!this.selectedProductionRun) {
          return null;
        } else {
          coverageStartDate = this.selectedProductionRun.start_date;
          coverageEndDate = this.selectedProductionRun.end_date;
        }
      } else if (this.selectedTimeFrame === SHIFT) {
        if (!this.selectedWorkShift) {
          return null;
        } else {
          coverageStartDate = this.selectedWorkShift.start_date;
          coverageEndDate = this.selectedWorkShift.end_date;
        }
      } else {
        return null;
      }

      if (!coverageStartDate && !coverageEndDate) {
        return null;
      }

      const startDate = DateTime.fromISO(coverageStartDate, { zone: this.timezone });
      const endDate = coverageEndDate
        ? DateTime.fromISO(coverageEndDate, { zone: this.timezone })
        : this.getCurrentCalendarDateTime();

      return {
        isOverlapping: startDate.toFormat(TimeUtils.DATE_FORMAT) !== endDate.toFormat(TimeUtils.DATE_FORMAT),
        startDate,
        endDate,
      };
    },
    selectedHourLabel() {
      if (this.isLiveData) {
        return `${this.selectedHour}:00 - ${this.$t("common.now")}`;
      } else {
        return `${this.selectedHour}:00 - ${this.selectedHour + 1}:00`;
      }
    },
  },
  methods: {
    ...mapActions("navigation", ["setTimeFrame"]),
    ...mapActions("dashboard", ["resetDashboardLoaders"]),
    ...mapActions("overview", ["resetOverviewLoaders"]),
    ...mapActions("user", ["updateSelectedPeriod"]),
    /*******************************
     * Utility Methods
     *******************************/
    isObjectEquals(a, b) {
      return Object.keys(a).length === Object.keys(b).length && Object.keys(a).every((p) => a[p] === b[p]);
    },
    onDatePickerClose() {
      this.selectedDateTime = this.date;
    },
    closeDatePicker() {
      this.isDatePickerOpen = false;
    },
    getCurrentProductionDateTime() {
      if (!this.activeFactory) return null;
      return TimeUtils.getBusinessDateTime(
        DateTime.now().setZone(this.timezone),
        this.productionDayMinutesFromMidnight,
        this.isCalendarDayBusinessDay,
      );
    },
    getCurrentCalendarDateTime() {
      if (!this.activeFactory) return null;
      return DateTime.now().setZone(this.timezone);
    },
    getMinDate() {
      return this.getCurrentProductionDateTime().minus({ months: this.maxPastMonths }).toFormat(TimeUtils.DATE_FORMAT);
    },
    getMaxDate() {
      return this.getCurrentProductionDateTime().toFormat(TimeUtils.DATE_FORMAT);
    },
    getDayAndMonth(dateTime) {
      if (!dateTime) return "";
      let month;
      switch (dateTime.month) {
        case 1: {
          month = this.$t("common.monthAbbreviation.january");
          break;
        }
        case 2: {
          month = this.$t("common.monthAbbreviation.february");
          break;
        }
        case 3: {
          month = this.$t("common.monthAbbreviation.march");
          break;
        }
        case 4: {
          month = this.$t("common.monthAbbreviation.april");
          break;
        }
        case 5: {
          month = this.$t("common.monthAbbreviation.may");
          break;
        }
        case 6: {
          month = this.$t("common.monthAbbreviation.june");
          break;
        }
        case 7: {
          month = this.$t("common.monthAbbreviation.july");
          break;
        }
        case 8: {
          month = this.$t("common.monthAbbreviation.august");
          break;
        }
        case 9: {
          month = this.$t("common.monthAbbreviation.september");
          break;
        }
        case 10: {
          month = this.$t("common.monthAbbreviation.october");
          break;
        }
        case 11: {
          month = this.$t("common.monthAbbreviation.november");
          break;
        }
        case 12: {
          month = this.$t("common.monthAbbreviation.december");
          break;
        }
      }
      switch (this.$i18n.locale) {
        case "fr":
          return `${dateTime.day} ${month}`;
        default:
          return `${month} ${dateTime.day}`;
      }
    },
    isSimplifiedChineseSelected() {
      return this.isSimplifiedChinese;
    },
    getDayOfWeek(dateTime) {
      if (!dateTime) return "";
      // 1 is Monday and 7 is Sunday
      switch (dateTime.weekday) {
        case 1: {
          return this.$t("common.dayNamesAbbreviation.monday");
        }
        case 2: {
          return this.$t("common.dayNamesAbbreviation.tuesday");
        }
        case 3: {
          return this.$t("common.dayNamesAbbreviation.wednesday");
        }
        case 4: {
          return this.$t("common.dayNamesAbbreviation.thursday");
        }
        case 5: {
          return this.$t("common.dayNamesAbbreviation.friday");
        }
        case 6: {
          return this.$t("common.dayNamesAbbreviation.saturday");
        }
        case 7: {
          return this.$t("common.dayNamesAbbreviation.sunday");
        }
      }
    },
    getFirstCoverageIndexOnOrAfterDate() {
      let coverageList = null;
      switch (this.selectedTimeFrame) {
        case SHIFT:
          coverageList = this.workShiftCoverage;
          break;
        case PRODUCTION_RUN:
          coverageList = this.productionRunCoverage;
          break;
      }
      if (!coverageList) return null;
      let coverageIndex = 0;
      for (let i = 0; i < coverageList.length; i++) {
        const coverageStartDateTime = DateTime.fromISO(coverageList[i].start_date, { zone: this.timezone });
        if (coverageStartDateTime < this.selectedDateTime) break;
        coverageIndex = i;
      }
      return coverageIndex;
    },
    onSelectedDate() {
      this.selectedTimeFrameController.selectDate();
      this.resetLoaders();
    },
    /*******************************
     * Time Frame Selection Methods
     *******************************/
    selectNextTimeFrame() {
      this.selectedTimeFrameController.selectNext();
      this.resetLoaders();
    },
    selectPreviousTimeFrame() {
      this.selectedTimeFrameController.selectPrevious();
      this.resetLoaders();
    },
    goToPresent() {
      this.selectedTimeFrameController.selectNow();
      this.resetLoaders();
    },
    resetLoaders() {
      if (this.isOverview) {
        // reset overview loaders
        this.resetOverviewLoaders();
      } else {
        // reset dashboard loaders
        this.resetDashboardLoaders();
      }
    },
    checkDateTime() {
      /**
       * Check if the current view needs to be switched over to the next one. This only happens
       * if we're currently viewing live data to keep the dashboard accurate.
       *
       * Do not check if looking at past data
       * Ex.
       *    - HOUR: Check if the current hour has finished
       *    - SHIFT: Check if the current shift has finished
       *    - PRODUCTION DAY: Check if a new production day has started
       *    - PRODUCTION RUN: Check if a new production run has been started
       */

      if (!this.isLiveData) return;
      this.selectedTimeFrameController.checkDateTime(this.getCurrentProductionDateTime());
    },
    /*******************************
     * Output
     *******************************/
    submit() {
      this.emitInputs();
      this.closeDatePicker();
    },
    emitInputs() {
      const timeFrameOptions = {
        timeFrame: this.selectedTimeFrame,
        date: this.selectedDateTime,
        hour: this.selectedHour,
        shiftIndex: this.selectedShiftIndex,
        isLastShift: this.selectedShiftIndex === 0,
        runIndex: this.selectedRunIndex,
        isLastRun: this.selectedRunIndex === 0,
        isOverview: this.isOverview,
      };
      /**
       * Saving the navigation parameters in the store will trigger the recalculation
       * of the start and end timestamps. This allows parent components to be completely
       * ignorant of the date picker's logic. All they need to do is watch the start/end
       * timestamps and re-fetch data when they change.
       *
       * This also allows the date picker to be more easily introduced in new views
       * without a complicated integration.
       */
      this.setTimeFrame(timeFrameOptions);
    },
    initializeOverviewDatePicker() {
      /**
       * If PRODUCTION RUN is selected in the dashboard, and the user switches to the overview, then
       * the date picker will switch to PRODUCTION DAY and select the start date of the shift or production run.
       *
       * If CURRENT SHIFT is selected, then it will select the current production day and set shiftIndex to 0 to be live
       *
       */
      if (this.timeFrame === SHIFT) {
        this.selectedTimeFrame = SHIFT;
        this.selectedShiftIndex = 0;
        this.selectedDateTime = TimeUtils.getBusinessDateTime(
          DateTime.now().setZone(this.activeFactory.timezone),
          this.activeFactory.productionDayMinutesFromMidnight,
          this.activeFactory.isCalendarDayBusinessDay,
        );
        this.submit();
      } else if (this.timeFrame === PRODUCTION_RUN) {
        this.selectedTimeFrame = PRODUCTION_DAY;
        if (this.isLiveData) {
          // select now to stay "live"
          this.selectedTimeFrameController.selectNow();
        } else {
          this.submit();
        }
      }
    },
    initializeData() {
      if (!this.activeFactory) return;
      this.selectedDateTime = this.date;
      if (this.isOverview && (this.timeFrame === PRODUCTION_RUN || this.timeFrame === SHIFT)) {
        this.initializeOverviewDatePicker();
      } else {
        this.selectedTimeFrame = this.timeFrame;
        this.selectedHour = this.dateData?.hour;
        this.selectedShiftIndex = this.timeFrame !== SHIFT ? this.dateData?.shiftIndex : 0; // overview can not navigate shifts so on mounted set to live shift (index 0) for dashboard
        this.selectedRunIndex = this.timeFrame !== PRODUCTION_RUN ? this.dateData?.runIndex : 0;
        if (this.isLiveData) {
          this.dateTimeInterval = setInterval(this.checkDateTime, DATE_TIME_INTERVAL);
        }
        this.submit();
      }
    },
  },
  mounted() {
    this.resetLoaders();
    this.initializeData();
  },
};
</script>

<style lang="scss">
// override dark background in the date picker :(
.v-picker.v-card {
  background-color: transparent !important;

  .v-picker__body {
    background-color: transparent !important;
  }
}
</style>
