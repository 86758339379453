<template>
  <v-card
    :wxid="$options.name"
    :class="[tileColor, { unfocused: isTileUnfocused }]"
    class="tile in-progress"
    tag="section"
  >
    <grouped-tile-selector-menu :tile-index="tileIndex" />
    <v-btn
      v-if="tileIsActive"
      @click="tileClick"
      :title="$t('dashboard.panelHeader.showJustificationPanel_hoverTitle')"
      :class="{ disabled: isPresenter || isDataSourceAlert }"
      class="tile-inner unjustified-counter"
      depressed
    >
      <h3 :title="primaryTitle" class="primary-title">
        {{ primaryTitle }}
      </h3>
      <h2 :title="primaryValue" class="primary-value">{{ primaryValue }}</h2>
      <span class="wx-typo-h3 font-weight-light text-wrap">{{ ongoingJustificationName }}</span>
      <template v-if="isLiveData && currentDowntime && toJustifyCount > 0">
        <section class="status-details">
          <dl>
            <dt>{{ $t("tiles.unjustifiedDowntimeCount") }}</dt>
            <dd>{{ toJustifyCount }}</dd>
          </dl>
        </section>
      </template>
    </v-btn>

    <div v-else class="tile-inner all-justified" :class="$vuetify.theme.dark ? 'theme--dark' : 'theme--light'">
      <h3 :title="primaryTitle" class="primary-title">
        {{ primaryTitle }}
      </h3>
      <h2 :title="primaryValue" class="primary-value">{{ primaryValue }}</h2>
    </div>
  </v-card>
</template>

<script>
import TileBase from "@/components/dashboard/tiles/TileBase";
import { Duration } from "luxon";
import { mapGetters, mapActions } from "vuex";
import GroupedTileSelectorMenu from "@/components/dashboard/tiles/GroupedTileSelectorMenu.vue";

export default {
  name: "TileInProgressDowntime",
  components: { GroupedTileSelectorMenu },
  extends: TileBase,
  inheritAttrs: false,
  computed: {
    ...mapGetters("dashboard", [
      "currentDowntime",
      "currentDowntimeDuration",
      "unjustifiedDowntimes",
      "partiallyJustifiedDowntimes",
      "oldestDataSourceAlert",
      "activePUJustificationDelayInSeconds",
    ]),
    ...mapGetters("navigation", ["isLiveData", "startDateTime", "endDateTime"]),
    ...mapGetters("user", ["isPresenter"]),
    ...mapGetters("tiles", ["selectedTileIndex"]),
    isTileUnfocused() {
      return this.selectedTileIndex !== null;
    },
    tileIsActive() {
      return (this.currentDowntime && this.isLiveData) || this.toJustifyCount > 0;
    },
    tileColor() {
      let baseColor;
      if (this.tileIsActive) {
        if (this.ongoingJustification && this.ongoingJustification.reason) {
          let isPlanned = this.ongoingJustification.reason.planned;
          baseColor = isPlanned ? this.plannedDowntimeCssClass : this.unplannedDowntimeCssClass;
        } else {
          baseColor = this.downtimeCssClass;
        }
      } else {
        baseColor = this.uptimeCssClass;
      }
      let disabledColorSuffix = this.isDataSourceAlert ? "-disabled" : "";
      return baseColor + disabledColorSuffix;
    },
    primaryTitle() {
      return this.isLiveData && this.currentDowntime
        ? this.$t("tiles.inProgressDowntime")
        : this.$t("tiles.unjustifiedDowntimeCount");
    },
    // Counter
    primaryValue() {
      return this.isLiveData && this.currentDowntime
        ? Duration.fromMillis(this.currentDowntimeDuration).toFormat("hh:mm:ss")
        : this.toJustifyCount;
    },
    ongoingJustificationName() {
      let reasonName = this.ongoingJustification?.reason?.name;
      return reasonName ? reasonName : "";
    },
    ongoingJustification() {
      if (
        this.isLiveData &&
        this.currentDowntime &&
        this.currentDowntime.justifications &&
        this.currentDowntime.justifications.length > 0
      ) {
        return this.currentDowntime.justifications.find((j) => j.end_time === null || j.end_time === undefined);
      }
      return null;
    },
    coverageStartTime() {
      return this.startDateTime.toMillis();
    },
    coverageEndTime() {
      return this.endDateTime.toMillis();
    },
    unjustifiedDowntimeCount() {
      if (!this.coverageStartTime || !this.coverageEndTime) return this.unjustifiedDowntimes.length;
      return this.unjustifiedDowntimes.filter(
        (udt) => udt.start_time < this.coverageEndTime && udt.end_time > this.coverageStartTime,
      ).length;
    },
    //calculated with blocks
    partiallyJustifiedDowntimeUnjustifiedInRangeDowntimeIds() {
      return this.partiallyJustifiedDowntimes
        .filter((pjdt) =>
          this.isPartiallyJustifiedDowntimeUnjustifiedInRange(pjdt, this.coverageStartTime, this.coverageEndTime),
        )
        .map((d) => d.id);
    },
    partiallyJustifiedWithImportantUnjustifiedPortionDowntimeIds() {
      return this.partiallyJustifiedDowntimes
        .filter(
          (d) =>
            !this.isPartiallyJustifiedDowntimeUnjustifiedInRange(d, this.coverageStartTime, this.coverageEndTime),
        )
        .filter((d) => !this.isAllUnjustifiedSectionUnderJustificationDelay(d))
        .map((d) => d.id);
    },
    toJustifyCount() {
      const partiallyJustifiedButUnjustifiedInRangeDowntimeIds = this.partiallyJustifiedDowntimeUnjustifiedInRangeDowntimeIds;
      const partiallyJustifiedWithImportantUnjustifiedPortionDowntimeIds = this.partiallyJustifiedWithImportantUnjustifiedPortionDowntimeIds;
      return (
        this.unjustifiedDowntimeCount +
        partiallyJustifiedButUnjustifiedInRangeDowntimeIds.length +
        partiallyJustifiedWithImportantUnjustifiedPortionDowntimeIds.length
      );
    },
    isDataSourceAlert() {
      return this.oldestDataSourceAlert !== null && this.oldestDataSourceAlert !== undefined;
    },
  },
  methods: {
    ...mapActions("dashboard", ["selectLastDowntime", "selectLastDowntimeUnjustified"]),
    isAllUnjustifiedSectionUnderJustificationDelay(downtime) {
      const justificationDelayInMillis = this.activePUJustificationDelayInSeconds * 1000;
      return downtime.justifications
        .filter((j) => j.reasonId === null)
        .every((j) => (j.end_time ? j.end_time : downtime.end_time) - j.start_time < justificationDelayInMillis);
    },
    tileClick() {
      if (this.toJustifyCount > 0) {
        this.selectLastDowntimeUnjustified();
      } else {
        this.selectLastDowntime();
      }
    },
    isPartiallyJustifiedDowntimeUnjustifiedInRange(downtime, startTime, endTime) {
      if (!downtime.justifications) return true; // if no justifications, then downtime has to be unjustified in the range
      const justificationsWithinRange = downtime.justifications.filter(
        (j) => j.start_time < endTime && (!j.end_time || j.end_time > startTime),
      );
      if (justificationsWithinRange.length === 0) {
        // no justifications, meaning this downtime has to be unjustified.
        return true;
      }
      const justificationsWithinRangeWithNoReason = justificationsWithinRange.filter((j) => !j.reason);
      if (justificationsWithinRangeWithNoReason.length > 0) {
        // some justifications still need a reason
        return true;
      }
      return false;
    },
  },
};
</script>
