<template>
  <v-card :wxid="$options.name" tag="section" class="wx-panel panel-giveaway">
    <header v-if="showGiveaway" class="panel-header">
      <h2 class="panel-title">
        <span class="label">{{ $t("dashboard.panelHeader.productLabel") }}</span>
        {{ currentProductFullName }}
      </h2>
      <v-btn-toggle v-if="showGiveaway" class="toggle ml-5" borderless dense>
        <wx-btn-standard dense color="primary" :disabled="productIndex <= 0" @click="() => productIndex--">
          <v-icon>mdi-chevron-left</v-icon>
        </wx-btn-standard>
        <wx-btn-standard
          dense
          color="primary"
          :disabled="productIndex === productLength - 1"
          @click="() => productIndex++"
        >
          <v-icon>mdi-chevron-right</v-icon>
        </wx-btn-standard>
      </v-btn-toggle>
      <v-spacer />
      <legend-giveaway-graph-dialog v-if="giveawayGraph && productLength > 0" />
    </header>
    <fragment v-if="showGiveaway">
      <v-alert
        class="mb-0"
        color="warning"
        dense
        text
        v-if="product && product.target_value && (!product.lower_tolerance || !product.upper_tolerance)"
        >{{ $t("dashboard.giveawaysGraph.noOrMissingTarget") }}
      </v-alert>
      <v-row v-if="giveawayGraph && productLength > 0">
        <v-col cols="12" class="d-flex flex-column align-stretch">
          <spc-giveaway-graph :product-index="productIndex" />
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col cols="12" class="d-flex flex-column justify-center align-center">
          <wx-alert-query-message :text="$t('dashboard.giveawaysGraph.noData')" no-results-icon />
        </v-col>
      </v-row>
      <timeline-tics />
    </fragment>
    <fragment v-else>
      <v-row>
        <v-col cols="12" class="d-flex flex-column justify-center align-center">
          <wx-alert-query-message :text="$t('dashboard.timelineMessageForProductionRuns')" no-results-icon />
        </v-col>
      </v-row>
    </fragment>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import WxBtnStandard from "@/components/ui/WxBtnStandard";
import SpcGiveawayGraph from "@/components/dashboard/SpcGiveawayGraph";
import TimelineTics from "@/components/dashboard/TimelineTics.vue";
import LegendGiveawayGraphDialog from "@/components/dashboard/dialogs/LegendGiveawayGraphDialog.vue";
import WxAlertQueryMessage from "@/components/ui/WxAlertQueryMessage";
import { dash } from "@/helpers";
import DemoService from "@/components/DemoService";

export default {
  name: "PanelGiveaway",
  props: {
    showGiveaway: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    LegendGiveawayGraphDialog,
    SpcGiveawayGraph,
    TimelineTics,
    WxBtnStandard,
    WxAlertQueryMessage,
  },
  data() {
    return {
      productIndex: 0,
    };
  },
  watch: {
    productLength() {
      this.productIndex = this.productLength - 1;
    },
    product() {
      const newCurrentSku = this.product ? this.product.sku : null;
      this.setGiveawayForSku(newCurrentSku);
      this.setAverageProductGiveawayForSku(newCurrentSku);
    },
  },
  computed: {
    ...mapGetters("dashboard", ["giveawayGraph", "activeProductionUnit"]),
    ...mapGetters("user", ["isPresenter", "email"]),
    ...mapGetters("navigation", ["activeFactory"]),
    product() {
      if (!this.giveawayGraph) return null;
      return this.giveawayGraph.products[this.productIndex];
    },
    targetLabel() {
      if (!this.product || !this.product.target_value) return "";
      const lowerCaseUnit = this.product.target_unit.toLowerCase();
      const unitLabel = this.getUnitLabel(lowerCaseUnit);
      return `${this.product.target_value.toFixed(1)} ${unitLabel}`;
    },
    productLength() {
      if (!this.giveawayGraph) return 0;
      return this.giveawayGraph.products.length;
    },
    currentProductFullName() {
      if (!this.giveawayGraph || this.productLength === 0) return dash;

      const product = this.giveawayGraph.products[this.productIndex];
      let productDescription = DemoService.maskProductNameIfNecessary(this.email, product.description);

      if (!product.sku) {
        return this.$t("product.listing.noData");
      } else if (productDescription && productDescription !== "") {
        return `${product.sku} - ${product.product_description}`;
      } else {
        return product.sku;
      }
    },
  },
  methods: {
    ...mapActions("dashboard", ["setGiveawayForSku", "setAverageProductGiveawayForSku"]),
    getUnitLabel(unit) {
      switch (unit) {
        case "kilogram":
          return this.$t("common.units.kilogram");
        case "gram":
          return this.$t("common.units.gram");
        case "ton":
          return this.$t("common.units.ton");
        case "pound":
          return this.$t("common.units.pound");
        case "ounce":
          return this.$t("common.units.ounce");
        case "meter":
          return this.$t("common.units.meter");
        case "feet":
          return this.$t("common.units.feet");
        case "liter":
          return this.$t("common.units.liter");
        default:
          return this.$t("common.units.unit");
      }
    },
  },
  mounted() {
    this.productIndex = this.productLength - 1;
  },
};
</script>

<style lang="scss" scoped>
.wx-panel.v-card {
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: var(--border-radius-lg); // Tiles border-radius
  padding-bottom: 35px;
}

.panel-header {
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;

  .panel-title {
    margin-right: auto;

    .label {
      font-weight: 400; /* Regular */
      margin-right: 0.15em;

      &::after {
        content: ":";
      }
      &:lang(fr)::after {
        padding-left: 0.2em;
      }
    }
  }

  // Single buttons
  ::v-deep > .product-btn,
  ::v-deep > .legend-btn {
    margin-left: var(--btn-inline-margin);
  }
}

.v-item-group.v-btn-toggle {
  & > .v-btn {
    width: 50%;

    // fix a vuetify glitch where after 2nd click an active button looks darker.
    opacity: 1;
    &.v-btn--active {
      &:hover::before,
      &::before {
        opacity: 0;
      }
    }
    // when active color is primary and UX = disable
  }
}
</style>
