<template>
  <div class="d-flex align-center">
    <wx-autocomplete
      v-if="!computedActiveTag && !isOverview"
      v-model="activeTagId"
      class="filter-style"
      :items="formattedFactoryTags"
      :dense="false"
      hide-details
      :placeholder="statusPlaceholderLabel"
      @input="emitNewTag"
    >
      <template v-slot:item="{ on, attrs, item }">
        <v-list-item v-bind="attrs" v-on="on" dense>
          <v-chip small label :color="item.color" style="cursor: pointer">{{ item.text }}</v-chip>
        </v-list-item>
      </template>
    </wx-autocomplete>
    <v-chip
      :class="['rounded-lg', { 'tag-ellipsis': isOverview }]"
      v-if="computedActiveTag"
      :color="computedActiveTag.color"
      @click:close="onCloseActiveTab"
      label
      :close="clearable"
    >
      <span class="mr-2">{{ this.computedActiveTagText }}</span>
      <span>{{ computedDuration }}</span>
    </v-chip>
  </div>
</template>

<script>
import WxAutocomplete from "@/components/ui/WxAutocomplete.vue";
import { mapGetters } from "vuex";
import { DateTime } from "luxon";

export default {
  name: "TagSelector",
  components: { WxAutocomplete },
  props: {
    activeTag: {
      type: Object,
      default: () => null,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    isOverview: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activeTagId: null,
      sinceDuration: null,
      sinceInterval: null,
    };
  },
  watch: {
    activeFactory() {
      this.initializeSinceInterval();
    },
    activeTag() {
      if (this.activeTag) {
        this.activeTagId = this.activeTag.id;
        this.initializeSinceInterval();
      } else {
        this.activeTagId = null;
        this.sinceDuration = null;
        clearInterval(this.sinceInterval);
      }
    },
  },
  computed: {
    ...mapGetters("user", ["language"]),
    ...mapGetters("navigation", ["factoryTags", "activeFactory"]),
    statusPlaceholderLabel() {
      return this.$t("productionUnitTags.status");
    },
    formattedFactoryTags() {
      return this.factoryTags.map((ft) => {
        return {
          value: ft.id,
          color: ft.color,
          text: ft.name,
        };
      });
    },
    computedActiveTag() {
      if (!this.activeTagId) return null;
      return this.factoryTags.find((ft) => ft.id === this.activeTagId);
    },
    computedActiveTagText() {
      if (!this.computedActiveTag) return null;
      return this.computedActiveTag.name;
    },
    computedDuration() {
      if (!this.sinceDuration) return null;
      return this.sinceDuration.toFormat("hh:mm:ss");
    },
  },
  methods: {
    onCloseActiveTab() {
      this.activeTagId = null;
      this.clearSinceInterval();
      this.emitNewTag();
    },
    clearSinceInterval() {
      this.sinceDuration = null;
      clearInterval(this.sinceInterval);
    },
    initializeSinceInterval() {
      if (!this.activeFactory || !this.activeTag) return;
      const since = DateTime.fromISO(this.activeTag.since, { zone: this.activeFactory.timezone });
      const now = DateTime.now().setZone(this.activeFactory.timezone);
      this.sinceDuration = now.diff(since, ["hours", "minutes", "seconds"]);
      clearInterval(this.sinceInterval);
      this.sinceInterval = setInterval(this.increaseDuration, 1000);
    },
    increaseDuration() {
      this.sinceDuration = this.sinceDuration.plus(1000);
    },
    emitNewTag() {
      this.$emit("update", this.activeTagId);
    },
  },
  mounted() {
    if (this.activeTag) {
      this.activeTagId = this.activeTag.id;
      this.initializeSinceInterval();
    }
  },
  beforeDestroy() {
    clearInterval(this.sinceInterval);
  },
};
</script>

<style lang="scss" scoped>
//This even weirder CSS is to apply 'text-overflow: ellipsis' to the tag name but not the duration, while working with a fluid width. To work with the fluid width, we need to calculate the max-width of the v-chip/tag to force a constraint so the ellipsis effect work. This is done with media queries and CSS variables.

.tag-ellipsis {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  //for xs default for 2 items per row */
  max-width: calc((100vw - (2 * var(--grid-gutter)) - (2 * var(--box-padding-dashboard) * 2) - (12px * 2)) / 2);

  span:first-child {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-grow: 1;
    min-width: 0;
  }

  span:last-child {
    flex-shrink: 0;
  }
}

/*Evaluate the pu-item/v-card width based on number of grid,breakpoint but also  grid gap, padding from puItem, padding from the view, width of navbar( not in xs/sm)
 ex: (100vw - (3 * var(--grid-gutter)) - (3 * var('puitem--padding') * 2) - ('padding--of--overView--view' * 2)) - ('navBar-width') / 3);*/
@media ($wx-sm-min) {
  .tag-ellipsis {
    max-width: calc((100vw - (3 * var(--grid-gutter)) - (3 * var(--box-padding-dashboard) * 2) - (20px * 2)) / 3);
  }
}

@media ($wx-md-min) {
  .tag-ellipsis {
    max-width: calc(
      (100vw - (4 * var(--grid-gutter)) - (4 * var(--box-padding-dashboard) * 2) - (24px * 2) - 56px) / 4
    );
  }
}

@media ($wx-lg-min) {
  .tag-ellipsis {
    max-width: calc(
      (100vw - (5 * var(--grid-gutter)) - (5 * var(--box-padding-dashboard) * 2) - (32px * 2) - 56px) / 5
    );
  }
}

@media ($wx-xl-min) {
  .tag-ellipsis {
    max-width: calc(
      (100vw - (6 * var(--grid-gutter)) - (6 * var(--box-padding-dashboard) * 2) - (40px * 2) - 56px) / 6
    );
  }
}
</style>
