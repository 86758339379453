<template>
  <v-dialog v-model="modalDialog" fullscreen>
    <template #activator="{ on: dialog, attrs }">
      <wx-btn-standard
        v-bind="attrs"
        v-on="{ ...dialog }"
        :ripple="false"
        :disabled="isPresenter"
        :title="$t('common.edit')"
        :class="btnCssClass"
        class="edit-production-run px-0"
        color="primary"
        active
        outlined
      >
        {{ $t("common.edit") }}
      </wx-btn-standard>
    </template>

    <article class="v-dialog__content">
      <header>
        <v-btn icon large @click="closeModalDialog" :title="$t('common.dialogFormCancelHoverTitle')" class="close-btn">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <h2 class="mb-2 md-8 wx-typo-h1">
          {{ $t("dashboard.conversionFactorOverride.title") }}
          <wx-contextualized-help
            :help-card-title="$t('dashboard.conversionFactorOverride.title')"
            :max-width="320"
            is-help-card
          >
            <template v-slot:help-card-text-slot>
              <p>
                {{ $t("dashboard.conversionFactorOverride.informationTooltip.text") }}
              </p>
              <p class="mb-0">
                <a
                  :href="$t('dashboard.conversionFactorOverride.informationTooltip.academyLink.href')"
                  :title="$t('dashboard.conversionFactorOverride.informationTooltip.academyLink.hint')"
                  target="productionRun"
                >
                  {{ $t("dashboard.conversionFactorOverride.informationTooltip.academyLink.text") }}
                </a>
              </p>
            </template>
          </wx-contextualized-help>
        </h2>
      </header>

      <conversion-factor-override-form
        v-if="modalDialog"
        ref="conversionFactorOverride"
        :initial-conversion-factor-override="conversionFactorOverride"
        @close="closeModalDialog"
        @submit="submitConversionFactorOverride"
      />
    </article>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import i18n from "@/i18n";
import ConversionFactorOverrideForm from "@/components/dashboard/conversionfactoroverride/ConversionFactorOverrideForm.vue";
import ErrorHandling from "@/components/ErrorHandling";
import WxBtnStandard from "@/components/ui/WxBtnStandard.vue";
import WxContextualizedHelp from "@/components/ui/WxContextualizedHelp.vue";

export default {
  name: "ModifyConversionFactorOverrideDialog",
  components: {
    ConversionFactorOverrideForm,
    WxBtnStandard,
    WxContextualizedHelp,
  },
  props: {
    conversionFactorOverrideMarker: {
      type: Object,
      required: true,
    },
    btnCssClass: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      modalDialog: false,
    };
  },
  computed: {
    ...mapGetters("user", ["isPresenter"]),
    conversionFactorOverride() {
      return {
        event_id: this.conversionFactorOverrideMarker.event_id,
        original_start: this.conversionFactorOverrideMarker.original_start,
        conversion_factor: this.conversionFactorOverrideMarker.label,
      };
    },
  },
  methods: {
    ...mapActions("dashboard", ["updateConversionFactorOverride"]),
    ...mapActions("operation", ["showOperationError"]),
    submitConversionFactorOverride(submission) {
      const payload = {
        event_id: submission.eventId,
        conversion_factor: submission.conversionFactor,
        effective_start_time: submission.startTime,
      };
      this.updateConversionFactorOverride(payload, submission.eventId)
        .then(this.closeModalDialog)
        .catch((error) => {
          this.showOperationError(
            ErrorHandling.buildErrorsMessages(error.response, (code) => {
              if (code === "DSH_CFO_PUT_20002") {
                return i18n.t("dashboard.conversionFactorOverride.errors.conflictStartTime");
              }
              return i18n.t("common.errors.default", { code: code });
            }),
          );
        });
    },
    closeModalDialog() {
      this.$emit("close");
      this.modalDialog = false;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-dialog {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--color-base-theme);

  &__content {
    display: flex;
    align-items: center;
    flex-flow: column nowrap;
    position: initial;
    left: auto;
    width: 100%;
    max-width: 600px;
    padding-inline: var(--grid-gutter);
    pointer-events: auto;

    header {
      padding-top: var(--grid-gutter);

      .close-btn {
        position: fixed;
        z-index: 1; // hover fields
        top: var(--dialog-close-offset);
        right: var(--dialog-close-offset);
      }
    }
    .v-form {
      width: 100%;

      fieldset {
        border: none;

        &.form-footer-actions {
          display: flex;
          justify-content: flex-end;
          column-gap: var(--btn-inline-margin);
          padding-bottom: var(--grid-gutter);
        }
      }

      // Responsive Columns
      .row {
        margin-top: 0;
        margin-bottom: 0;
        .field-col {
          padding-top: 0;
          padding-bottom: 0;
        }
      }
    }
  }
}

.v-btn {
  &.product-selected,
  &.no-product-selected {
    font-weight: 400;
    font-size: var(--font-size-h3);
    max-height: 30px;

    ::v-deep .v-btn__content {
      .text-string {
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
</style>
