<template>
  <v-dialog :wxid="$options.name" v-model="dialog" max-width="var(--modal-window-narrow-width)">
    <template #activator="{ on }">
      <wx-btn-standard v-on="on" :title="$t('common.delete')" color="error" outlined>
        {{ $t("common.delete") }}
      </wx-btn-standard>
    </template>

    <v-card :wxid="$options.name" tag="article" class="wx-confirmation-dialog-card">
      <v-card-title tag="header">
        <v-icon class="mb-3 mb-md-4 mb-xl-6">mdi-delete-outline</v-icon>
        <h3 class="heading">{{ $t("dashboard.conversionFactorOverride.delete.questionConfirmToDelete") }} ?</h3>
      </v-card-title>
      <v-card-text>
        <v-alert type="warning" icon="mdi-alert-circle-outline" text outlined>
          {{ $t("dashboard.conversionFactorOverride.delete.deletionDescription") }}
        </v-alert>
      </v-card-text>
      <v-card-actions tag="footer" class="d-flex flex-button-column-gap">
        <wx-btn-standard
          @click="dialog = false"
          :title="$t('deletionConfirmationDialog.cancelBtnHint')"
          class="cancel-btn flex-grow-1"
          large
        >
          {{ $t("common.cancel") }}
        </wx-btn-standard>
        <wx-btn-standard
          @click="submitDeletion"
          :title="$t('deletionConfirmationDialog.deleteBtnHint')"
          color="error"
          class="flex-grow-1"
          large
        >
          {{ $t("dashboard.conversionFactorOverride.delete.deleteBtn") }}
        </wx-btn-standard>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import i18n from "@/i18n";
import ErrorHandling from "@/components/ErrorHandling";
import WxBtnStandard from "@/components/ui/WxBtnStandard";

export default {
  name: "DeleteConversionFactorOverrideDialog",
  components: {
    WxBtnStandard,
  },
  props: {
    conversionFactorOverrideMarker: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    ...mapActions("dashboard", ["deleteConversionFactorOverride"]),
    ...mapActions("operation", ["showOperationError"]),
    closeModalDialog() {
      this.$emit("close");
      this.dialog = false;
    },
    submitDeletion() {
      this.deleteConversionFactorOverride(this.conversionFactorOverrideMarker.event_id)
        .then(this.closeModalDialog)
        .catch((error) => {
          this.showOperationError(
            ErrorHandling.buildErrorsMessages(error.response, (code) => {
              return i18n.t("common.errors.default", { code: code });
            }),
          );
        });
    },
  },
  computed: {
    ...mapGetters("navigation", ["activeFactoryProductionUnits"]),
    ...mapGetters("dashboard", ["activeProductionUnit"]),
  },
};
</script>

<style lang="scss" scoped>
.wx-confirmation-dialog-card {
  &.v-card {
    position: relative;
    max-width: var(--modal-window-medium-width);
    padding: var(--dialog-padding);
    background-color: var(--color-element-layer2);
    border-radius: var(--border-radius-lg);
    opacity: 1;

    .v-card__text {
      font-size: var(--font-size-norm);
    }
    .heading,
    p {
      text-align: center;
    }

    &,
    .v-icon,
    .v-card__text,
    .v-card__actions,
    .v-expansion-panel-content {
      color: var(--color-text-theme);
    }

    .v-card {
      // Card Header
      &__title {
        flex-direction: column;
        justify-content: center;
        padding-top: 0;
        padding-bottom: var(--dialog-padding);
        word-break: break-word;

        p {
          margin-bottom: 0;
        }
      }
    }
  }
}
</style>
