<template>
  <v-dialog v-model="modalDialog" :wxid="$options.name" fullscreen>
    <article class="v-dialog__content">
      <header>
        <v-btn icon large @click="closeModalDialog" :title="$t('common.dialogFormCancelHoverTitle')" class="close-btn">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <h2 class="mb-2 md-8 wx-typo-h1">
          {{ $t("dashboard.conversionFactorOverride.title") }}
          <wx-contextualized-help
            :help-card-title="$t('dashboard.conversionFactorOverride.title')"
            :max-width="320"
            is-help-card
          >
            <template v-slot:help-card-text-slot>
              <p>
                {{ $t("dashboard.conversionFactorOverride.informationTooltip.text") }}
              </p>
              <p class="mb-0">
                <a
                  :href="$t('dashboard.conversionFactorOverride.informationTooltip.academyLink.href')"
                  :title="$t('dashboard.conversionFactorOverride.informationTooltip.academyLink.hint')"
                  target="productionRun"
                >
                  {{ $t("dashboard.conversionFactorOverride.informationTooltip.academyLink.text") }}
                </a>
              </p>
            </template>
          </wx-contextualized-help>
        </h2>
      </header>

      <conversion-factor-override-form
        v-if="modalDialog"
        ref="conversionFactorOverrideForm"
        @close="closeModalDialog"
        @submit="submitConversionFactorOverride"
      />
    </article>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ConversionFactorOverrideForm from "@/components/dashboard/conversionfactoroverride/ConversionFactorOverrideForm.vue";
import ErrorHandling from "@/components/ErrorHandling";
import i18n from "@/i18n";
import WxContextualizedHelp from "@/components/ui/WxContextualizedHelp.vue";

export default {
  name: "CreateConversionFactorOverrideDialog",
  components: {
    ConversionFactorOverrideForm,
    WxContextualizedHelp,
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    btnCssClass: {
      type: String,
      default: null,
    },
  },
  model: {
    prop: "modelValue",
    event: "update:modelValue",
  },
  computed: {
    ...mapGetters("user", ["isPresenter"]),
    modalDialog: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
  methods: {
    ...mapActions("dashboard", ["createConversionFactorOverride"]),
    ...mapActions("operation", ["showOperationError"]),
    submitConversionFactorOverride(submission) {
      const payload = {
        conversion_factor: submission.conversionFactor,
        effective_start_time: submission.startTime,
      };
      this.createConversionFactorOverride(payload)
        .then(this.closeModalDialog)
        .catch((error) => {
          this.showOperationError(
            ErrorHandling.buildErrorsMessages(error.response, (code) => {
              if (code === "DSH_CFO_POST_20002") {
                return i18n.t("dashboard.conversionFactorOverride.errors.conflictStartTime");
              }
              return i18n.t("common.errors.default", { code: code });
            }),
          );
        });
    },
    closeModalDialog() {
      this.$refs.conversionFactorOverrideForm.reset();
      this.modalDialog = false;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-dialog {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--color-base-theme);

  &__content {
    display: flex;
    align-items: center;
    flex-flow: column nowrap;
    position: initial;
    left: auto;
    width: 100%;
    max-width: 600px;
    padding-inline: var(--grid-gutter);
    pointer-events: auto;

    header {
      padding-top: var(--grid-gutter);

      .close-btn {
        position: fixed;
        z-index: 1; // hover fields
        top: var(--dialog-close-offset);
        right: var(--dialog-close-offset);
      }
    }
    .v-form {
      width: 100%;

      fieldset {
        border: none;

        &.form-footer-actions {
          display: flex;
          justify-content: flex-end;
          column-gap: var(--btn-inline-margin);
          padding-bottom: var(--grid-gutter);
        }
      }

      // Responsive Columns
      .row {
        margin-top: 0;
        margin-bottom: 0;
        .field-col {
          padding-top: 0;
          padding-bottom: 0;
        }
      }
    }
  }
}
</style>
