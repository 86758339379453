import http from "../../BaseServiceHttp";

class GiveawaySamplingService {
  fetchSessionInProgress(productionUnitId) {
    return http.get(`/production-units/${productionUnitId}/sampling-session-in-progress`);
  }

  fetchSessionSamples(productionUnitId, sessionId) {
    return http.get(`/production-units/${productionUnitId}/sampling-sessions/${sessionId}/samples`);
  }

  createSamplingSession(productionUnitId, httpData, samplingType) {
    let mode = samplingType === 'assisted' ? 'automated' : 'manual';
    return http.post(`/production-units/${productionUnitId}/sampling-sessions?mode=${mode}`, httpData);
  }

  cancelSamplingSession(productionUnitId, sessionId, comment) {
    const payload = {
      comment: comment,
    };
    return http.put(`/production-units/${productionUnitId}/sampling-sessions/${sessionId}/cancel`, payload);
  }

  completeSamplingSession(productionUnitId, sessionId, comment) {
    const payload = {
      comment: comment,
    };
    return http.put(`/production-units/${productionUnitId}/sampling-sessions/${sessionId}/complete`, payload);
  }

  createSessionSample(productionUnitId, sessionId, value, unit) {
    let payload = {
      value: value,
      unit: unit, 
    };
    return http.post(`/production-units/${productionUnitId}/sampling-sessions/${sessionId}/samples`, payload);
  }

  deleteSessionSample(productionUnitId, sessionId, sampleId) {
    return http.delete(`/production-units/${productionUnitId}/sampling-sessions/${sessionId}/samples/${sampleId}`);
  }

  updateSessionSample(productionUnitId, sessionId, sampleId, value, unit) {
    let payload = {
      value: value,
      unit: unit, 
    };
    return http.put(`/production-units/${productionUnitId}/sampling-sessions/${sessionId}/samples/${sampleId}`, payload);
  }
}

export default new GiveawaySamplingService();
