<template>
  <v-card
    :wxid="$options.name"
    class="tile"
  >
    <grouped-tile-selector-menu :tile-index="tileIndex" />
    <div class="tile-inner">
        <h3 :title="$t('tiles.unknown')" class="primary-title">
            <v-icon>mdi-alert-circle</v-icon>
            {{ $t("tiles.unknown") }}
        </h3>
    </div>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import GroupedTileSelectorMenu from "@/components/dashboard/tiles/GroupedTileSelectorMenu";
import TileBase from "@/components/dashboard/tiles/TileBase";

export default {
  name: "TileUnknown",
  extends: TileBase,
  inheritAttrs: false,
  components: { GroupedTileSelectorMenu },
  computed: {
    ...mapGetters("tiles", ["selectedTileIndex"]),
  },
}
</script>