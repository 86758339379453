import { mapActions, mapGetters } from "vuex";
import { getUnitName } from "@/components/dashboard/tileselectormenu/TileHelper";

export default {
  data() {
    return {
      noConversionFound: false,
    };
  },
  computed: {
    ...mapGetters("dashboard", ["currentProductId", "activeProductionUnit", "productionUnitProducts"]),
  },
  watch: {
    unit() {
      this.showWarningIfNeeded();
    },
    currentProductId(newVal, prevVal) {
      if (newVal === prevVal) return;
      this.showWarningIfNeeded();
    },
    value(newVal, oldVal) {
      if (oldVal === null && newVal !== null) this.showWarningIfNeeded();
    },
  },
  methods: {
    ...mapActions("operation", ["showOperationWarning"]),
    showWarningIfNeeded() {
      if (
        !this.unit || // if no unit, we're showing the PU converted unit name therefore we don't show any messages
        this.unit === this.activeProductionUnit.converted_unit_name || // don't show messages if unit equals PU converted unit name
        this.unit === this.activeProductionUnit.convertedUnitName
      ) {
        this.noConversionFound = false; // The unit IS the same as the PU converted unit!
        return;
      }

      if (this.value === undefined) {
        this.noConversionFound = true;
        if (!this.currentProductId) {
          const unitName = getUnitName(this.activeProductionUnit.convertedUnitName).name ?? getUnitName(this.activeProductionUnit.converted_unit_name).name;
          this.showOperationWarning({
            title: this.$t("tiles.noConversionWarning.title", { tile: this.title }),
            message: this.$t("tiles.noConversionWarning.noProductWarning", { unitName }),
            timeout: 10000,
          });
        } else if (this.currentProductId) {
          const unitName = getUnitName(this.unit).name;
          this.showOperationWarning({
            title: this.$t("tiles.noConversionWarning.title", { tile: this.title }),
            message: this.$t("tiles.noConversionWarning.productWarning", {
              unit: unitName,
              currentProductId: this.currentProductId,
            }),
            timeout: 10000,
          });
        }
      } else {
        this.noConversionFound = false;
      }
    },
  },
  mounted() {
    this.showWarningIfNeeded();
  },
};
