<template>
  <v-card class="tile">
    <wx-skeleton-loader class="tile" type="image" />
  </v-card>
</template>

<script>
import TileBase from "@/components/dashboard/tiles/TileBase";
import WxSkeletonLoader from "@/components/ui/WxSkeletonLoader.vue";

export default {
  name: "TileLoading",
  components: { WxSkeletonLoader },
  extends: TileBase,
  inheritAttrs: false,
};
</script>
