<template>
  <v-card
    :wxid="$options.name"
    :class="{ disabled: isTileDisabled, unfocused: isTileUnfocused }"
    class="tile total-uptime"
    tag="section"
    sf="1"
  >
    <grouped-tile-selector-menu :tile-index="tileIndex" />
    <div class="tile-inner">
      <h3 :title="$t('tiles.totalUptime')" class="primary-title">
        {{ $t("tiles.totalUptime_shortName") }}
        <v-icon v-if="!isTileAuthorized()">mdi-lock</v-icon>
      </h3>
      <h2 class="primary-value">{{ primaryValue }}</h2>
    </div>
  </v-card>
</template>

<script>
import { Duration } from "luxon";
import { mapGetters } from "vuex";
import GroupedTileSelectorMenu from "@/components/dashboard/tiles/GroupedTileSelectorMenu";
import TileBase from "@/components/dashboard/tiles/TileBase";
import Tiles from "@/components/Tiles";

export default {
  name: "TileTotalUptime",
  extends: TileBase,
  inheritAttrs: false,
  components: { GroupedTileSelectorMenu },
  computed: {
    ...mapGetters("dashboard", ["timeDistribution"]),
    ...mapGetters("packages", ["activePuHasRequiredFeature"]),
    ...mapGetters("tiles", ["selectedTileIndex"]),
    isTileUnfocused() {
      return this.selectedTileIndex !== null && this.tileIndex !== this.selectedTileIndex;
    },
    isTileDisabled() {
      return !this.isTileAuthorized();
    },
    // Uptime
    primaryValue() {
      if (this.timeDistribution !== null && this.timeDistribution.uptime !== null && this.isTileAuthorized()) {
        return Duration.fromMillis(this.timeDistribution.uptime).toFormat("hh':'mm':'ss");
      } else {
        return "—";
      }
    },
  },
  methods: {
    isTileAuthorized() {
      return this.activePuHasRequiredFeature(Tiles.availability.requiredFeature);
    },
  },
};
</script>
