<template>
  <v-card
    :wxid="$options.name"
    tag="section"
    class="wx-panel panel-production-timeline"
    :class="{ unfocused: tileSelected }"
  >
    <header class="panel-header">
      <div class="panel-title d-flex flex-column flex-md-row">
        <v-btn-toggle v-model="productionRunButton" class="mr-sm-3 mobile-squeezed-btn" multiple>
          <wx-btn-standard
            @click="showProductionRunDialog()"
            :title="$t('dashboard.productionRun.newProductionRunBtnHoverTitle')"
            :disabled="isPresenter"
            color="primary"
          >
            <v-icon left>mdi-plus</v-icon>
            <span class="text-string">{{ $t("dashboard.productionRun.newProductionRunBtn") }}</span>
          </wx-btn-standard>
          <v-menu offset-y nudge-bottom="8">
            <template v-slot:activator="{ on: menuOn, attrs: menuAttrs }">
              <wx-btn-standard color="primary" v-bind="menuAttrs" v-on="menuOn" :disabled="isPresenter">
                <v-icon>mdi-chevron-down</v-icon>
              </wx-btn-standard>
            </template>
            <v-list>
              <v-list-item @click="showProductionRunDialog()">
                <v-list-item-content>
                  <span class="wx-typo-sm">{{ $t("dashboard.productionRun.newProductionRunBtn") }}</span>
                </v-list-item-content>
              </v-list-item>
              <v-list-item @click="showConversionFactorDialog()">
                <v-list-item-content>
                  <span class="wx-typo-sm">{{ $t("dashboard.conversionFactorOverride.title") }}</span>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-btn-toggle>

        <div v-if="isMobilePhone" class="d-flex flex-column-gap mt-3 mb-2">
          <manual-rejects-entry
            v-if="isPuManualReject() && !isPresenter"
            btn-css-class="mr-sm-3 mobile-squeezed-btn"
          />

          <wx-btn-standard
            v-if="!selectedDowntime"
            @click="selectLastDowntime"
            :title="$t('dashboard.panelHeader.showJustificationPanel_hoverTitle')"
            :disabled="noDowntimesVisible"
            class="toggle-jutifications-btn mobile-squeezed-btn"
          >
            {{ $t("dashboard.panelHeader.toggleJustificationsBtn") }}
            <v-icon right>mdi-arrow-top-right-bottom-left</v-icon>
          </wx-btn-standard>
          <wx-btn-standard
            v-if="selectedDowntime"
            @click="unselectDowntime"
            :title="$t('dashboard.panelHeader.hideJustificationPanel_hoverTitle')"
            class="toggle-jutifications-btn mobile-squeezed-btn"
            color="primary"
          >
            {{ $t("dashboard.panelHeader.toggleJustificationsBtn") }}
            <v-icon right>mdi-close</v-icon>
          </wx-btn-standard>
        </div>
      </div>

      <manual-rejects-entry
        v-if="isPuManualReject() && !isMobilePhone && !isPresenter"
        :btn-css-class="showTimeline ? 'mx-sm-1 mr-sm-4' : null"
      />

      <wx-btn-standard
        v-if="!isMobilePhone && !selectedDowntime && !isPresenter && showTimeline"
        :title="$t('dashboard.panelHeader.showJustificationPanel_hoverTitle')"
        :disabled="noDowntimesVisible"
        @click="selectLastDowntime"
        class="toggle-jutifications-btn"
      >
        {{ $t("dashboard.panelHeader.toggleJustificationsBtn") }}
        <v-icon right>mdi-arrow-top-right-bottom-left</v-icon>
      </wx-btn-standard>
      <wx-btn-standard
        v-if="!isMobilePhone && selectedDowntime && !isPresenter"
        :title="$t('dashboard.panelHeader.hideJustificationPanel_hoverTitle')"
        @click="unselectDowntime"
        class="toggle-jutifications-btn"
        color="primary"
      >
        {{ $t("dashboard.panelHeader.toggleJustificationsBtn") }}
        <v-icon right>mdi-close</v-icon>
      </wx-btn-standard>

      <legend-oee-graph-dialog v-if="showTimeline" />

      <wx-btn-icon
        v-if="showTimeline"
        :title="$t('dashboard.panelHeader.chooseWhichChartToDisplay_hoverTitle')"
        class="legend-btn"
        @click="openGraphKpiMenu"
      >
        <v-icon>mdi-dots-vertical</v-icon>
      </wx-btn-icon>
    </header>

    <fragment v-if="showTimeline">
      <timeline-markers />
      <timeline-production-blocks />
      <timeline-tics v-if="!isOEEGraph" />
      <timeline-oee-graph v-if="isOEEGraph" />
      <timeline-quantity-graph v-if="isQuantityGraph" class="mt-6" />
      <timeline-tics v-if="isOEEGraph" />
    </fragment>
    <fragment v-else>
      <v-row>
        <v-col cols="12" class="d-flex flex-column justify-center align-center">
          <wx-alert-query-message :text="noTimelineAvailable" no-results-icon />
        </v-col>
      </v-row>
    </fragment>
    <!--  Dialogs  -->
    <create-production-run-dialog v-model="showCreateProductionRunDialog" />
    <create-conversion-factor-override-dialog v-model="showCreateConversionFactorDialog" />
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import CreateConversionFactorOverrideDialog from "@/components/dashboard/conversionfactoroverride/CreateConversionFactorOverrideDialog.vue";
import CreateProductionRunDialog from "@/components/dashboard/productionRun/CreateProductionRunDialog";
import ManualRejectsEntry from "@/components/dashboard/ManualRejectsEntry";
import WxBtnStandard from "@/components/ui/WxBtnStandard";
import LegendOeeGraphDialog from "@/components/dashboard/dialogs/LegendOeeGraphDialog.vue";
import TimelineMarkers from "@/components/dashboard/TimelineMarkers";
import TimelineProductionBlocks from "@/components/dashboard/TimelineProductionBlocks";
import TimelineOeeGraph from "@/components/dashboard/TimelineOeeGraph";
import TimelineQuantityGraph from "@/components/dashboard/TimelineQuantityGraph";
import TimelineTics from "@/components/dashboard/TimelineTics.vue";
import PackageFeatures from "@/components/PackageFeatures";
import WxBtnIcon from "@/components/ui/WxBtnIcon";
import WxAlertQueryMessage from "@/components/ui/WxAlertQueryMessage.vue";
import { PRODUCTION_RUN } from "@/store/TimeUtils";
import ProductionUnitService from "@/components/productionunit/ProductionUnitService";
import Graphs from "@/components/Graphs";

export default {
  name: "PanelProductionTimeline",
  props: {
    showTimeline: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    WxAlertQueryMessage,
    CreateConversionFactorOverrideDialog,
    CreateProductionRunDialog,
    ManualRejectsEntry,
    LegendOeeGraphDialog,
    TimelineMarkers,
    TimelineProductionBlocks,
    TimelineOeeGraph,
    TimelineTics,
    TimelineQuantityGraph,
    WxBtnStandard,
    WxBtnIcon,
  },
  data() {
    return {
      selectedMarkerId: "",
      downtimeToggleValue: 0,
      selectedGraph: {
        selected_kpi: Graphs.quantity.name,
        config: {
          product_unit: "unit",
        },
      },
      showCreateProductionRunDialog: false,
      showCreateConversionFactorDialog: false,
      productionRunButton: [],
    };
  },
  watch: {
    selectedDowntime() {
      this.downtimeToggleValue = this.selectedDowntime ? 1 : 0;
    },
    activeProductionUnitId() {
      this.selectedGraph = this.activeDashboardPreferencesGraph;
    },
    activeDashboardPreferencesGraph() {
      this.selectedGraph = this.activeDashboardPreferencesGraph;
    },
    productionRunButton() {
      if (this.productionRunButton.length === 0) return;
      // this is just to always have both button unselected so that they visually look the same
      this.$nextTick(() => {
        this.productionRunButton = [];
      });
    },
  },
  computed: {
    ...mapGetters("dashboard", [
      "downtimes",
      "selectedDowntime",
      "activeDashboardPreferencesGraph",
      "activeProductionUnit",
      "activeProductionUnitId",
      "productionUnitIsNetQuantityEnabled",
    ]),
    ...mapGetters("user", ["isPresenter"]),
    ...mapGetters("packages", ["activePuHasRequiredFeature"]),
    ...mapGetters("tiles", ["selectedTileIndex"]),
    ...mapGetters("navigation", ["timeFrame"]),
    tileSelected() {
      return this.selectedTileIndex !== null;
    },
    noTimelineAvailable() {
      if (this.timeFrame === PRODUCTION_RUN) {
        return this.$t("dashboard.timelineMessageForProductionRuns");
      } else {
        return this.$t("dashboard.timelineMessage");
      }
    },
    noDowntimesVisible() {
      return this.downtimes.length === 0;
    },
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    isMobilePhone() {
      const vbpName = this.$vuetify.breakpoint.name;
      let phoneBreakpoint = false;
      if (vbpName === "xs") {
        phoneBreakpoint = true;
      }
      return phoneBreakpoint;
    },
    convertedUnitName() {
      let unitName = this.activeProductionUnit?.converted_unit_name;
      return unitName ? ProductionUnitService.getUnitName(unitName) : ProductionUnitService.getUnitName(null);
    },
    isOEEGraph() {
      return this.selectedGraph?.selected_kpi === Graphs.oeeTrend.name;
    },
    isQuantityGraph() {
      return !this.isOEEGraph;
    },
  },
  methods: {
    ...mapActions("dashboard", ["unselectDowntime", "selectLastDowntime"]),
    ...mapActions("graphs", ["setIsMenuOpen"]),
    showProductionRunDialog() {
      this.showCreateConversionFactorDialog = false;
      this.showCreateProductionRunDialog = true;
    },
    showConversionFactorDialog() {
      this.showCreateProductionRunDialog = false;
      this.showCreateConversionFactorDialog = true;
    },
    isPuManualReject() {
      return this.activePuHasRequiredFeature(PackageFeatures.reject) && !this.productionUnitIsNetQuantityEnabled;
    },
    openGraphKpiMenu() {
      this.setIsMenuOpen(true);
    },
  },
  mounted() {
    this.selectedGraph = this.activeDashboardPreferencesGraph;
  },
};
</script>

<style lang="scss" scoped>
.wx-panel.v-card {
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: var(--border-radius-lg); // Tiles border-radius
  padding-bottom: 35px;
}

.panel-header {
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;

  .panel-title {
    margin-right: auto;

    ::v-deep .v-btn {
      &.product-selected,
      &.no-product-selected {
        .v-btn__content {
          @media (max-width: $wx-max-width-phone-vertical) {
            max-width: 130px;
          }
        }
      }
      &.product-selected {
        .v-btn__content {
          @media (min-width: $wx-min-width-phone-vertical) {
            max-width: 300px;
          }
          @media (min-width: $wx-min-width-tablet-vertical) {
            max-width: 420px;
          }
          @media ($wx-md-min) {
            max-width: 735px;
          }
        }
      }
    }
  }

  // Single buttons
  ::v-deep > .v-btn {
    &.legend-btn {
      margin-left: 4px;

      @media (min-width: $wx-min-width-tablet-vertical) {
        margin-left: var(--btn-inline-margin);
      }
    }
  }

  .toggle {
    > :first-child {
      border-radius: var(--border-radius-form-elements) 0 0 var(--border-radius-form-elements);
    }
    > :last-child {
      border-radius: 0 var(--border-radius-form-elements) var(--border-radius-form-elements) 0;
    }

    // when active UX = disable
    .v-btn.v-btn--active {
      pointer-events: none;
      cursor: auto;
    }
  }
}
.truncated {
  text-overflow: ellipsis;
  overflow: hidden;
  min-width: 200px;
}

.mobile-squeezed-btn {
  ::v-deep &.v-btn {
    flex-grow: 1;
    flex-shrink: 1;
    @media (max-width: $wx-breakpoint-phone-vertical) {
      padding-inline: 5px !important;
    }
  }
}

.graphOptionMenu {
  max-width: 250px;
}
.selectedGraphIcon {
  color: inherit;
  float: right;
}
.unfocused {
  opacity: var(--opacity-disabled-effect);
}
</style>
