<template>
  <v-card
    :wxid="$options.name"
    :class="{ disabled: isTileDisabled, unfocused: isTileUnfocused }"
    class="tile reject-quantity secondary-heading"
    tag="section"
    sf="3"
  >
    <grouped-tile-selector-menu :tile-index="tileIndex" />
    <div class="tile-inner">
      <h3 :title="title" class="primary-title">
        {{ title }}
        <v-icon v-if="!isTileAuthorized()">mdi-lock</v-icon>
      </h3>
      <h2 class="primary-value d-flex">
        {{ netQuantityValue }}
        <v-tooltip v-if="isNegativeNetQuantity" max-width="300" top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon class="ml-5" color="warning" v-bind="attrs" v-on="on">$alertIcon</v-icon>
          </template>
          <span>{{ $t("tiles.netQuantity.negativeNetQuantityWarning") }}</span>
        </v-tooltip>
      </h2>
      <template>
        <h3 class="primary-title">{{ $t("tiles.rejectQuantity_unit", { unit: unitName }) }}</h3>
        <h2 class="primary-value">{{ rejectQuantityValue }}</h2>
      </template>
    </div>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import ProductionUnitService from "@/components/productionunit/ProductionUnitService";
import GroupedTileSelectorMenu from "@/components/dashboard/tiles/GroupedTileSelectorMenu";
import TileBase from "@/components/dashboard/tiles/TileBase";
import Tiles from "@/components/Tiles";
import Helpers from "@/helpers";
import TileService from "@/components/dashboard/tiles/TileService";
import { getUnitName } from "@/components/dashboard/tileselectormenu/TileHelper";
import UnitConversionWarningMixin from "@/components/dashboard/tiles/UnitConversionWarningMixin";

export default {
  name: "TileRejectQuantity",
  extends: TileBase,
  inheritAttrs: false,
  mixins: [UnitConversionWarningMixin],
  components: { GroupedTileSelectorMenu },
  computed: {
    ...mapGetters("dashboard", [
      "productionUnitConvertedUnit",
      "rejectQuantity",
      "allProductsQuantity",
      "activeProductionUnitId",
    ]),
    ...mapGetters("packages", ["activePuHasRequiredFeature"]),
    ...mapGetters("tiles", ["selectedTileIndex"]),
    ...mapGetters("user", ["preferences"]),
    isTileUnfocused() {
      return this.selectedTileIndex !== null && this.tileIndex !== this.selectedTileIndex;
    },
    isTileDisabled() {
      return !this.isTileAuthorized();
    },
    unit() {
      const tile = TileService.getDashboardTile(this.activeProductionUnitId, this.tileIndex, this.preferences);
      return tile?.config?.product_unit ?? null;
    },
    title() {
      return this.$t("tiles.netQuantity.name_unit", { unit: this.unitName });
    },
    unitName() {
      const unitName = this.unit
        ? getUnitName(this.unit).name
        : ProductionUnitService.getUnitName(this.productionUnitConvertedUnit);
      if (this.productionUnitConvertedUnit && this.isTileAuthorized()) {
        return " - " + unitName;
      } else {
        return "";
      }
    },
    // All Production Quantity minus Rejects
    netQuantityValue() {
      const rejects = this.rejectValue ? this.rejectValue : 0;
      return Helpers.getRoundedValueOrDash(this.allProductsQuantity(this.unit) - rejects, this.isTileAuthorized());
    },

    // Reject Quantity
    rejectValue() {
      if (this.rejectQuantity(this.unit) === null || this.rejectQuantity(this.unit) === undefined) return null;
      return this.rejectQuantity(this.unit);
    },
    value() {
      // used for the UnitConversionWarning mixin, only check if null or undefined. Can return either reject or net quantity
      return this.rejectQuantity(this.unit);
    },
    rejectQuantityValue() {
      return Helpers.getRoundedValueOrDash(this.rejectValue, this.isTileAuthorized());
    },

    isNegativeNetQuantity() {
      if (this.isNumber(this.netQuantityValue)) {
        return this.netQuantityValue < 0;
      } else {
        return false;
      }
    },
  },
  methods: {
    isTileAuthorized() {
      return this.activePuHasRequiredFeature(Tiles.rejectQuantity.requiredFeature);
    },
    isNumber(value) {
      return typeof value === 'number';
    }
  },
};
</script>
