<template>
  <section :wxid="$options.name" class="quantity-graph-container">
    <figure>
      <wx-chart-bar-graph
        v-if="isDataReady()"
        :chart-data="getChartData()"
        :chart-options="getChartOptions()"
        css-classes="canvas-container"
      />
    </figure>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import WxChartBarGraph from "@/components/ui/WxChartBarGraph.vue";
import StyleValues from "@/styles/_values.scss";
import * as TimeUtils from "@/store/TimeUtils";
import * as UnitUtils from "@/UnitUtils";
import ProductionUnitService from "@/components/productionunit/ProductionUnitService";
import Graphs from "@/components/Graphs";
import Helpers, { dash } from "@/helpers";

export default {
  name: "TimelineQuantityGraph",
  components: {
    WxChartBarGraph,
  },
  computed: {
    ...mapGetters("dashboard", ["quantityGraph", "activeDashboardPreferencesGraph", "productionUnitConvertedUnit"]),
    ...mapGetters("navigation", ["activeFactory", "durationMillis"]),
    ...mapGetters("user", ["theme"]),
  },
  methods: {
    isDataReady() {
      if (this.quantityGraph && this.quantityGraph.quantityData) {
        let entries = Object.entries(this.quantityGraph.quantityData);
        return entries.length > 0;
      }
      return false;
    },
    getUnitLabel(selectedKpi, selectedKpiUnit) {
      switch (selectedKpi) {
        case Graphs.quantity.name:
        case Graphs.rejectQuantity.name: {
          return ProductionUnitService.getUnitName(selectedKpiUnit);
        }
        case Graphs.weight.name:
        case Graphs.volume.name:
        case Graphs.length.name:
        case Graphs.rejectWeight.name:
        case Graphs.rejectVolume.name:
        case Graphs.rejectLength.name: {
          return UnitUtils.getUnitLabel(selectedKpiUnit);
        }
      }
      return dash;
    },
    getProductUnit(graphKpiConfig) {
      if (graphKpiConfig.product_unit) {
        return graphKpiConfig.product_unit;
      } else {
        return this.productionUnitConvertedUnit;
      }
    },
    getChartOptions() {
      if (this.quantityGraph && this.quantityGraph.quantityData) {
        let selectedKpi = this.activeDashboardPreferencesGraph.selected_kpi;
        let graphKpiConfig = this.activeDashboardPreferencesGraph.config;
        let unit = this.getProductUnit(graphKpiConfig);
        let unitName = this.getUnitLabel(selectedKpi, unit);

        let quantities = [];
        let entries = Object.entries(this.quantityGraph.quantityData);
        if (entries.length === 0) return;
        entries.forEach((pair) => {
          let skuQuantities = pair[1];
          skuQuantities.forEach((skuQty) => {
            let qty;
            switch (selectedKpi) {
              case Graphs.weight.name: {
                qty = UnitUtils.convertKilogramTo(skuQty.weight, unit);
                break;
              }
              case Graphs.rejectWeight.name: {
                qty = UnitUtils.convertKilogramTo(skuQty.rejected_weight, unit);
                break;
              }
              case Graphs.length.name: {
                qty = UnitUtils.convertMeterTo(skuQty.length, unit);
                break;
              }
              case Graphs.rejectLength.name: {
                qty = UnitUtils.convertMeterTo(skuQty.rejected_length, unit);
                break;
              }
              case Graphs.volume.name: {
                qty = UnitUtils.convertLiterTo(skuQty.volume, unit);
                break;
              }
              case Graphs.rejectVolume.name: {
                qty = UnitUtils.convertLiterTo(skuQty.rejected_volume, unit);
                break;
              }
              case Graphs.rejectQuantity.name: {
                let quantityByUnit = skuQty.quantity_by_unit;
                if (quantityByUnit) {
                  let quantityForUnit = quantityByUnit.find((q) => q.unit === unit);
                  qty = quantityForUnit?.rejected_qty ?? 0;
                } else {
                  qty = null;
                }
                break;
              }
              default: {
                let quantityByUnit = skuQty.quantity_by_unit;
                if (quantityByUnit) {
                  let quantityForUnit = quantityByUnit.find((q) => q.unit === unit);
                  qty = quantityForUnit?.qty ?? 0;
                } else {
                  qty = null;
                }
                break;
              }
            }
            quantities.push(Number(qty));
          });
        });
        let sortedQuantities = quantities.sort((a, b) => (a > b ? 1 : b > a ? -1 : 0));
        let highestValue = sortedQuantities[sortedQuantities.length - 1];

        let maxValue = highestValue;
        if (maxValue < 10.0) {
          maxValue = Helpers.round(maxValue, 1);
        } else {
          maxValue = Helpers.round(maxValue, 0);
        }

        let midValue = highestValue * 0.5;
        if (midValue < 10.0) {
          midValue = Helpers.round(midValue, 1);
        } else if (midValue < 100.0) {
          midValue = Helpers.round(midValue, 0);
        } else if (midValue < 1000.0) {
          midValue = Math.ceil(midValue / 10) * 10;
        } else if (midValue < 10000.0) {
          midValue = Math.ceil(midValue / 100) * 100;
        } else {
          midValue = Math.ceil(midValue / 1000) * 1000;
        }

        return {
          borderRadius: 3,
          animation: {
            duration: 0,
          },
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            x: {
              // display: false,
              stacked: true,
              grid: {
                display: false,
              },
              ticks: { color: this.theme === "light" ? "black" : "white" },
            },
            y: {
              min: 0,
              display: false,
              stacked: true,
              grid: {
                display: false,
              },
              beginAtZero: true,
              ticks: { color: this.theme === "light" ? "black" : "white" },
            },
          },
          plugins: {
            title: {
              display: false,
            },
            // Required to prevent Maximum call stack exceeded error
            annotation: {
              annotations: {
                zero: {
                  type: "line",
                  yMin: 0.0,
                  yMax: 0.0,
                  borderColor: this.theme === "light" ? StyleValues.color_errorLight : StyleValues.color_errorDark,
                  borderWidth: 1,
                  label: {
                    content: "",
                    enabled: true,
                    position: "end",
                    color: this.theme === "light" ? "hsla(0, 0%, 0%, 0.6)" : "hsla(0, 0%, 100%, 0.5)",
                    backgroundColor: "transparent",
                    yAdjust: 15,
                    textAlign: "center",
                  },
                },
                halfValueHorizontalLine: {
                  type: "line",
                  yMin: midValue,
                  yMax: midValue,
                  borderColor: this.theme === "light" ? StyleValues.color_errorLight : StyleValues.color_errorDark,
                  borderWidth: 1,
                  borderDash: [3, 3],
                  label: {
                    content: String(midValue) + " " + unitName,
                    enabled: true,
                    position: "end",
                    color: this.theme === "light" ? "hsla(0, 0%, 0%, 0.6)" : "hsla(0, 0%, 100%, 0.5)",
                    backgroundColor: "transparent",
                    yAdjust: 10,
                    textAlign: "center",
                  },
                },
                maxValueHorizontalLine: {
                  type: "line",
                  yMin: maxValue,
                  yMax: maxValue,
                  borderColor: this.theme === "light" ? StyleValues.color_errorLight : StyleValues.color_errorDark,
                  borderWidth: 1,
                  borderDash: [3, 3],
                  label: {
                    content: String(maxValue) + " " + unitName,
                    enabled: true,
                    position: "end",
                    color: this.theme === "light" ? "hsla(0, 0%, 0%, 0.6)" : "hsla(0, 0%, 100%, 0.5)",
                    backgroundColor: "transparent",
                    yAdjust: 10,
                    textAlign: "center",
                  },
                },
              },
            },
            legend: {
              display: false,
            },
            tooltip: {
              enabled: true,
              displayColors: false,
              backgroundColor:
                this.theme === "light"
                  ? StyleValues.color_baseBackground_themeDark
                  : StyleValues.color_baseBackground_themeLight,
              bodyFont: { size: 14, weight: "bold" },
              titleFont: "{weight: 'normal'}",
              titleColor: this.theme === "light" ? StyleValues.color_text_themeDark : StyleValues.color_text_themeLight,
              bodyColor: this.theme === "light" ? StyleValues.color_text_themeDark : StyleValues.color_text_themeLight,
              footerFont: "{weight: 'normal'}",
              footerColor:
                this.theme === "light" ? StyleValues.color_text_themeDark : StyleValues.color_text_themeLight,
              callbacks: {
                title: function title(tooltipItems) {
                  let tooltipItem = tooltipItems != null && tooltipItems instanceof Array ? tooltipItems[0] : null;
                  if (tooltipItem) {
                    let tooltipSKU = tooltipItem.dataset.label !== "null" ? tooltipItem.dataset.label : "No Product";
                    return "SKU: " + tooltipSKU;
                  } else {
                    return "";
                  }
                },
                label: function label(tooltipItem) {
                  return tooltipItem.dataset.data[tooltipItem.dataIndex] + " " + unitName;
                },
                footer: function footer(tooltipItems) {
                  let tooltipItem = tooltipItems != null && tooltipItems instanceof Array ? tooltipItems[0] : null;
                  if (tooltipItem) {
                    let dataset = tooltipItem.dataset;
                    if (dataset) {
                      const point = dataset.times[tooltipItem.dataIndex];
                      if (point) {
                        return point;
                      }
                    }
                  } else {
                    return "";
                  }
                },
              },
            },
          },
        };
      }
    },
    findDatesNotInSecondArray(firstArray, secondArray) {
      // Create a Set of strings representing the objects in the second array
      const secondArraySet = new Set(secondArray.map((obj) => `${obj.start_time}-${obj.end_time}`));

      // Find objects in the first array that are not in the second array
      const missingObjects = firstArray.filter((obj) => {
        const key = `${obj.start_time}-${obj.end_time}`;
        return !secondArraySet.has(key);
      });

      let missingObjectsWithNullQty = missingObjects.map((obj) => {
        return {
          ...obj,
          sku: null,
          quantity: null,
          unit: null,
        };
      });

      // Pad the second array with missing objects
      const paddedSecondArray = secondArray.concat(missingObjectsWithNullQty);

      return paddedSecondArray.sort((a, b) => a.start_time - b.start_time);
    },
    getChartData() {
      let graphKpiConfig = this.activeDashboardPreferencesGraph.config;
      let unit = this.getProductUnit(graphKpiConfig);

      let datasets = Object.entries(this.quantityGraph.quantityData).map((pair, index) => {
        let sku = pair[0];
        let skuQuantities = pair[1];
        let newValues = this.findDatesNotInSecondArray(this.quantityGraph.labels, skuQuantities);
        let times = this.formatTimeLabels(newValues, true);
        return {
          categoryPercentage: 0.7,
          barPercentage: 0.5,
          backgroundColor: this.getLineColor(index),
          label: sku,
          data: newValues.map((skuQty) => {
            switch (this.activeDashboardPreferencesGraph.selected_kpi) {
              case Graphs.weight.name: {
                return UnitUtils.convertKilogramTo(skuQty.weight, unit);
              }
              case Graphs.rejectWeight.name: {
                return UnitUtils.convertKilogramTo(skuQty.rejected_weight, unit);
              }
              case Graphs.length.name: {
                return UnitUtils.convertMeterTo(skuQty.length, unit);
              }
              case Graphs.rejectLength.name: {
                return UnitUtils.convertMeterTo(skuQty.rejected_length, unit);
              }
              case Graphs.volume.name: {
                return UnitUtils.convertLiterTo(skuQty.volume, unit);
              }
              case Graphs.rejectVolume.name: {
                return UnitUtils.convertLiterTo(skuQty.rejected_volume, unit);
              }
              case Graphs.rejectQuantity.name: {
                let quantityByUnit = skuQty.quantity_by_unit;
                if (quantityByUnit) {
                  let quantityForUnit = quantityByUnit.find((q) => q.unit === unit);
                  return quantityForUnit?.rejected_qty ?? 0;
                } else {
                  return null;
                }
              }
              default: {
                let quantityByUnit = skuQty.quantity_by_unit;
                if (quantityByUnit) {
                  let quantityForUnit = quantityByUnit.find((q) => q.unit === unit);
                  return quantityForUnit?.qty ?? 0;
                } else {
                  return null;
                }
              }
            }
          }),
          unit: ProductionUnitService.getUnitName(unit),
          times: times,
        };
      });
      let labels = this.formatTimeLabels(this.quantityGraph.labels, false);
      return {
        labels: labels,
        datasets: datasets,
      };
    },
    formatTimeLabels(array, includeEndTime) {
      return array.map((x) => this.formatTime(x.start_time, x.end_time, this.activeFactory.timezone, includeEndTime));
    },
    formatDateAndTime(startTime, endTime, timezone, includeEndTime) {
      let start = `${TimeUtils.toDateTimeNoTimezone(startTime, timezone)}`;
      let end = includeEndTime ? ` - ${TimeUtils.toDateTimeNoTimezone(endTime, timezone)}` : "";
      return start + end;
    },
    formatTime(startTime, endTime, timezone, includeEndTime) {
      let start = `${TimeUtils.toTimeHHMM(startTime, timezone)}`;
      let end = includeEndTime ? ` - ${TimeUtils.toTimeHHMM(endTime, timezone)}` : "";
      return start + end;
    },
    getLineColor(index) {
      if (this.theme === "dark") {
        return StyleValues[`wxData_lineColor${(index % StyleValues.wxData_colorsCount) + 1}Dark`];
      } else {
        return StyleValues[`wxData_lineColor${(index % StyleValues.wxData_colorsCount) + 1}Light`];
      }
    },
    isCoverageOver24Hours() {
      return this.durationMillis > 86400000;
    },
  },
};
</script>

<style lang="scss" scoped>
.quantity-graph-container {
  $containerDefaultHeight: 40px;

  flex: 1 0 auto;
  position: relative;
  overflow: hidden;
  height: ($containerDefaultHeight * 2);
  margin-top: 10px;

  @media ($wx-isNotMobile) {
    height: $containerDefaultHeight;
  }

  .canvas-container {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;

    ::v-deep canvas {
      height: 100%;
      width: 100%;
    }
  }
}
</style>
