import http from "../../BlobServiceHttp";

class MeasureExportService {
  fetchMeasureData(productionUnitId, startTime, endTime) {
    const startParam = encodeURIComponent(startTime);
    const endParam = encodeURIComponent(endTime);

    return http.get(
      `production-units/${productionUnitId}/measures/export?start_time=${startParam}&end_time=${endParam}`,
    );
  }

  fetchGiveawaySampleData(productionUnitId, startTime, endTime) {
    const startParam = encodeURIComponent(startTime);
    const endParam = encodeURIComponent(endTime);

    return http.get(
      `production-units/${productionUnitId}/samples/export?start_time=${startParam}&end_time=${endParam}`,
    );
  }
}

export default new MeasureExportService();
