<template>
  <BarChartGenerator
    v-if="chartData"
    :key="`${chartId}-${chartIndex}`"
    :chart-options="getChartOptions()"
    :chart-data="chartData"
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="width"
    :height="height"
  />
</template>

<script>
import { Bar as BarChartGenerator } from 'vue-chartjs/legacy';
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement } from 'chart.js';

ChartJS.register(Title, Tooltip, Legend, BarElement);

import "chartjs-adapter-luxon";
export default {
  name: 'WXChartBarGraph',
  components: {
    BarChartGenerator
  },
  props: {
    chartId: {
      type: String,
      default: "bar-chart",
    },
    datasetIdKey: {
      type: String,
      default: "bar-chart",
    },
    width: {
      type: Number,
      default: 200
    },
    height: {
      type: Number,
      default: 450
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => {}
    },
    plugins: {
      type: Array,
      default: () => []
    },
    chartData: {
      type: Object,
      default: () => {},
    },
    chartOptions: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      chartIndex: 1,
    };
  },
  watch: {
    theme() {
      this.chartIndex++;
    },
    language() {
      this.chartIndex++;
    },
  },
  methods: {
    getChartOptions() {
      return { ...this.chartOptions };
    },
  },
}
</script>
<style lang="scss" scoped></style>