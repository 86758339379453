<template>
  <v-card
    :wxid="$options.name"
    :class="{ disabled: isTileDisabled, unfocused: isTileUnfocused }"
    class="tile total-downtime secondary-heading"
    tag="section"
    sf="1"
  >
    <grouped-tile-selector-menu :tile-index="tileIndex" />
    <div class="tile-inner">
      <h3 :title="$t('tiles.totalUnplannedDowntime')" class="primary-title">
        {{ $t("tiles.totalUnplannedDowntime") }}
        <v-icon v-if="!isTileAuthorized()">mdi-lock</v-icon>
      </h3>
      <h2 class="primary-value">{{ primaryValue }}</h2>
      <template>
        <h3 class="primary-title">{{ $t("tiles.totalPlannedDowntime") }}</h3>
        <h2 class="primary-value">{{ plannedDowntimeValue }}</h2>
      </template>
    </div>
  </v-card>
</template>

<script>
import { Duration } from "luxon";
import { mapGetters } from "vuex";
import GroupedTileSelectorMenu from "@/components/dashboard/tiles/GroupedTileSelectorMenu";
import TileBase from "@/components/dashboard/tiles/TileBase";
import Tiles from "@/components/Tiles";

export default {
  name: "TileTotalDowntime",
  extends: TileBase,
  inheritAttrs: false,
  components: { GroupedTileSelectorMenu },
  computed: {
    ...mapGetters("dashboard", ["timeDistribution"]),
    ...mapGetters("packages", ["activePuHasRequiredFeature"]),
    ...mapGetters("tiles", ["selectedTileIndex"]),
    isTileUnfocused() {
      return this.selectedTileIndex !== null && this.tileIndex !== this.selectedTileIndex;
    },
    isTileDisabled() {
      return !this.isTileAuthorized();
    },
    // Unplanned Downtime
    primaryValue() {
      if (
        this.timeDistribution !== null &&
        this.timeDistribution.downtime_unplanned !== null &&
        this.timeDistribution.downtime_unjustified != null &&
        this.isTileAuthorized()
      ) {
        const totalDowntimeMillis =
          this.timeDistribution.downtime_unplanned + this.timeDistribution.downtime_unjustified;
        return Duration.fromMillis(totalDowntimeMillis).toFormat("hh':'mm':'ss");
      } else {
        return "—";
      }
    },
    plannedDowntimeValue() {
      if (
        this.timeDistribution !== null &&
        this.timeDistribution.downtime_planned !== null &&
        this.isTileAuthorized()
      ) {
        return Duration.fromMillis(this.timeDistribution.downtime_planned).toFormat("hh':'mm':'ss");
      } else {
        return "—";
      }
    },
  },
  methods: {
    isTileAuthorized() {
      return this.activePuHasRequiredFeature(Tiles.availability.requiredFeature);
    },
  },
};
</script>
