<template>
  <v-dialog v-model="show" max-width="600" persistent>
    <v-card class="pa-5">
      <v-card-title>
        <v-icon class="mr-2">mdi-alert-circle-outline</v-icon>
        {{ $t("dashboard.productSampling.cancelSessionDialog.title") }}
      </v-card-title>
      <v-card-text class="pl-14">
        {{ $t("dashboard.productSampling.cancelSessionDialog.content") }}
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <wx-btn-standard color="secondary" @click="back">{{
          $t("dashboard.productSampling.cancelSessionDialog.back")
        }}</wx-btn-standard>
        <wx-btn-standard color="primary" @click="cancel">{{
          $t("dashboard.productSampling.cancelSessionDialog.cancel")
        }}</wx-btn-standard>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import WxBtnStandard from "@/components/ui/WxBtnStandard.vue";

export default {
  components: { WxBtnStandard },
  name: "ConfirmCancelDialog",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    back() {
      this.$emit("back");
    },
    cancel() {
      this.$emit("cancel");
    },
  },
};
</script>

<style lang="scss" scoped>
.v-card {
  background-color: var(--color-base-background);
}
</style>
