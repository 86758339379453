export default class DayTimeFrameController {
  constructor(vueInstance) {
    this.vue = vueInstance;
  }
  onSelectedTimeFrame() {
    // find the first shift in the coverage that starts on or after the selected date
    // if none found, select the current work shift
    // set the date as the start date of that shift for if the user returns to PRODUCTION_DAY
    this.vue.selectedShiftIndex = this.vue.isOverview ? 0 : this.vue.getFirstCoverageIndexOnOrAfterDate();
    this.vue.selectedHour = null;
    this.vue.selectedRunIndex = null;
    this.vue.submit();
  }
  isPreviousTimeFrameDisabled() {
    if (!this.vue.workShiftCoverage || this.vue.isOverview) return true; // disable shift navigation if no coverage is provided
    return this.vue.selectedShiftIndex === this.vue.workShiftCoverage.length - 1;
  }
  isNextTimeFrameDisabled() {
    return this.vue.isLiveData; // If we're viewing live data, we should not be able to next a next time frame
  }
  isPresentButtonDisabled() {
    return this.vue.isLiveData;
  }
  showNavigation() {
    return !this.vue.isOverview; // only show navigation if not in overview (aka. hide navigation in overview)
  }
  showPeriodName() {
    return !this.vue.isOverview; // only show the period name in the dashboard and not in the overview
  }
  getDateLabel() {
    const overlappingInfo = this.vue.coverageOverlappingInfo;
    if (!overlappingInfo) return "";
    if (overlappingInfo.isOverlapping) {
      const from = `${this.vue.getDayOfWeek(overlappingInfo.startDate)} ${this.vue.getDayAndMonth(
        overlappingInfo.startDate,
      )}`;
      const to = `${this.vue.getDayOfWeek(overlappingInfo.endDate)} ${this.vue.getDayAndMonth(
        overlappingInfo.endDate,
      )}`;
      return this.vue.isSimplifiedChineseSelected() ? `${from}日 - ${to}日` : `${from} - ${to}`;
    } else {
      let date = `${this.vue.getDayOfWeek(overlappingInfo.startDate)} ${this.vue.getDayAndMonth(
        overlappingInfo.startDate,
      )}`;
      return this.vue.isSimplifiedChineseSelected() ? `${date}日` : date;
    }
  }
  selectPrevious() {
    // shift coverage is sorted desc (aka. current shift is at index 0)
    // to go to a previous shift, we need to increase the index
    if (!this.vue.workShiftCoverage || this.vue.selectedShiftIndex >= this.vue.workShiftCoverage.length - 1) return;
    this.vue.selectedShiftIndex = this.vue.selectedShiftIndex + 1;
  }
  selectNext() {
    // shift coverage is sorted desc (aka. current shift is at index 0)
    // to go to a previous shift, we need to decrease the index
    if (!this.vue.workShiftCoverage || this.vue.selectedShiftIndex === 0) return;
    this.vue.selectedShiftIndex = this.vue.selectedShiftIndex - 1;
  }
  selectNow() {
    if (!this.vue.workShiftCoverage) return; // navigation disabled if no work shift coverage provided
    this.vue.selectedShiftIndex = 0;
  }
  selectDate() {
    this.vue.selectedShiftIndex = this.vue.getFirstCoverageIndexOnOrAfterDate();
  }
  checkDateTime() {
    // should not have to do anything since if live, the index will be 0
    // if a new work shift was started, it will now appear at the first index
    // of the coverage therefore the selected work shift will update
  }
}
