<template>
  <v-card
    :wxid="$options.name"
    :class="{ disabled: isTileDisabled, unfocused: isTileUnfocused }"
    class="tile production"
    tag="section"
    sf="1"
  >
    <grouped-tile-selector-menu :tile-index="tileIndex" />
    <div class="tile-inner">
      <h3 :title="$t('tiles.timeToCompletion.title')" class="primary-title">
        {{ $t("tiles.timeToCompletion.title") }}
        <v-icon v-if="!isTileAuthorized()">mdi-lock</v-icon>
      </h3>

      <fragment v-if="isProductionInProgress">
        <h2 class="primary-value">{{ isLiveData ? completionTime : dash }}</h2>
        <section class="status-details">
          <dl>
            <dt>{{ speedTitle }}</dt>
            <dd>
              {{ isLiveData ? productionSpeedPerHour : dash }}
            </dd>
          </dl>
        </section>
      </fragment>
      <fragment v-else>
        <h2 class="primary-value wrap">{{ dash }}</h2>
        <p class="value-sub-text__unit null">{{ $t("common.none") }}</p>
      </fragment>
    </div>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import ProductionUnitService from "@/components/productionunit/ProductionUnitService";
import GroupedTileSelectorMenu from "@/components/dashboard/tiles/GroupedTileSelectorMenu";
import TileBase from "@/components/dashboard/tiles/TileBase";
import Tiles from "@/components/Tiles";
import Helpers, { dash } from "@/helpers";
import { toTimeToCompletion } from "@/components/ProductionRunCompletionService";

export default {
  name: "TileTimeToCompletion",
  extends: TileBase,
  inheritAttrs: false,
  components: { GroupedTileSelectorMenu },
  data() {
    return {
      dash: dash,
    };
  },
  computed: {
    ...mapGetters("dashboard", [
      "currentProductionRunCoverage",
      "currentPlannedQuantity",
      "productionUnitConvertedUnit",
      "currentProductionRunMetrics",
    ]),
    ...mapGetters("packages", ["activePuHasRequiredFeature"]),
    ...mapGetters("navigation", ["isLiveData"]),
    ...mapGetters("tiles", ["selectedTileIndex"]),
    isTileUnfocused() {
      return this.selectedTileIndex !== null && this.tileIndex !== this.selectedTileIndex;
    },
    completionTime() {
      return this.timeToCompletion.completionTime ?? dash;
    },
    productionSpeedPerHour() {
      return Helpers.round(this.timeToCompletion.productionSpeedPerHour, 1) ?? dash;
    },
    timeToCompletion() {
      return toTimeToCompletion(
        this.currentPlannedQuantity,
        this.currentProductionRunMetrics?.produced_quantity?.total_count,
        this.currentProductionRunMetrics?.reject_quantity?.total_count,
        this.currentProductionRunMetrics?.time_distribution,
      );
    },
    isTileDisabled() {
      return !this.isTileAuthorized();
    },
    speedTitle() {
      return this.$t("tiles.timeToCompletion.speed", [this.unitName]);
    },
    isProductionInProgress() {
      return this.currentProductionRunCoverage !== null;
    },
    unitName() {
      if (this.productionUnitConvertedUnit && this.isTileAuthorized()) {
        return ProductionUnitService.getUnitName(this.productionUnitConvertedUnit);
      } else {
        return "";
      }
    },
  },
  methods: {
    isTileAuthorized() {
      return this.activePuHasRequiredFeature(Tiles.timeToCompletion.requiredFeature);
    },
  },
};
</script>

<style lang="scss">
.wrap {
  overflow-wrap: normal;
}
</style>
