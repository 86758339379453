<template>
  <v-card
    :wxid="$options.name"
    :class="{ disabled: isTileDisabled, unfocused: isTileUnfocused }"
    class="tile cycle-time secondary-heading"
    tag="section"
    sf="1"
  >
    <grouped-tile-selector-menu :tile-index="tileIndex" />
    <div class="tile-inner">
      <h3 :title="$t('tiles.currentCycleTime')" class="primary-title">
        {{ $t("tiles.currentCycleTime") }}
        <v-icon v-if="!isTileAuthorized()">mdi-lock</v-icon>
      </h3>
      <h2 class="primary-value">{{ primaryValue }}</h2>
      <template>
        <h3 class="primary-title">{{ $t("tiles.averageCycleTime") }}</h3>
        <h2 class="primary-value">{{ averageCycleTimeValue }}</h2>
      </template>
    </div>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import GroupedTileSelectorMenu from "@/components/dashboard/tiles/GroupedTileSelectorMenu";
import TileBase from "@/components/dashboard/tiles/TileBase";
import Tiles from "@/components/Tiles";
import { Duration } from "luxon";
import Helpers from "@/helpers";


export default {
  name: "TileCycleTime",
  extends: TileBase,
  inheritAttrs: false,
  components: { GroupedTileSelectorMenu },
  computed: {
    ...mapGetters("dashboard", ["cycleStats", "currentTimelineBlock"]),
    ...mapGetters("packages", ["activePuHasRequiredFeature"]),
    ...mapGetters("tiles", ["selectedTileIndex"]),
    isTileUnfocused() {
      return this.selectedTileIndex !== null && this.tileIndex !== this.selectedTileIndex;
    },
    isTileDisabled() {
      return !this.isTileAuthorized();
    },
    // Current Cycle Time 
    primaryValue() {
      if (
        this.currentTimelineBlock &&
        this.currentTimelineBlock !== null &&
        this.currentTimelineBlock.state === "up" &&
        this.isTileAuthorized()
      ) {
        const currentCycleTimeInMillis = this.currentTimelineBlock.duration * 1000;
        return Duration.fromMillis(currentCycleTimeInMillis).toFormat("hh':'mm':'ss");
      } else {
        return "—";
      }
    },
    // Average Cycle Time
    averageCycleTimeValue() {
      if (
        this.cycleStats !== null &&
        this.cycleStats.total_cycle_time !== null &&
        !isNaN(this.cycleStats.total_cycle_time) &&
        this.cycleStats.average_cycle_time != null && 
        this.isTileAuthorized()
      ) {
        const averageCycleTimeInMillisHelper = Helpers.round((this.cycleStats.average_cycle_time * 1000), 1)
        return Duration.fromMillis(averageCycleTimeInMillisHelper).toFormat("hh':'mm':'ss");
      } else {
        return "—";
      }
    },
  },
  methods: {
    isTileAuthorized() {
      return this.activePuHasRequiredFeature(Tiles.cycleTime.requiredFeature);
    },
  },
};
</script>
