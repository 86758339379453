<template>
  <section :wxid="$options.name" class="oee-graph-container">
    <figure>
      <wx-chart-line-graph
        css-classes="canvas-container"
        :styles="{
          left: oeeGraphOffset,
          width: oeeGraphWidth,
        }"
        :chart-data="oeeChartData"
        :chart-options="oeeChartOptions"
      />
    </figure>
  </section>
</template>

<script>
import StyleValues from "@/styles/_values.scss";
import WxChartLineGraph from "@/components/ui/WxChartLineGraph.vue";
import i18n from "@/i18n";
import { mapGetters } from "vuex";
import * as TimeUtils from "@/store/TimeUtils";

export default {
  name: "TimelineOeeGraph",
  components: {
    WxChartLineGraph,
  },
  data() {
    return {
      oeeChartMaxValue: 125,
    };
  },
  computed: {
    ...mapGetters("dashboard", ["oeeGraph"]),
    ...mapGetters("navigation", ["activeFactory"]),
    ...mapGetters("user", ["theme"]),
    toleranceErrorColor() {
      return this.theme === "light" ? StyleValues.color_errorLight : StyleValues.color_errorDark;
    },
    oeeChartOptions() {
      return {
        animation: {
          duration: 0,
        },
        title: {
          display: false,
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          y: {
            display: false,
            min: -1,
            max: 125,
            ticks: {
              max: 126,
              min: -1,
            },
            grid: {
              display: false,
            },
          },
          x: {
            display: false,
            grid: {
              display: false,
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          annotation: {
            annotations: {
              target: this.oeeGraph
                ? {
                    type: "line",
                    yMin: this.oeeGraph.target,
                    yMax: this.oeeGraph.target,
                    borderColor: this.theme === "light" ? StyleValues.color_errorLight : StyleValues.color_errorDark,
                    borderWidth: 1,
                    borderDash: [3, 3],
                    label: {
                      content: this.$t("dashboard.oeeTarget", { target: this.oeeGraph.target }),
                      enabled: true,
                      position: "end",
                      color: this.theme === "light" ? StyleValues.color_errorLight : StyleValues.color_errorDark,
                      backgroundColor: "transparent",
                      yAdjust: 15,
                      textAlign: "center",
                    },
                  }
                : null,
            },
          },
          tooltip: {
            /**
             * DESIGN NOTE
             * Tooltips are displayed in inverted theme colors.
             * This is why the following logic on `this.theme === "light"`
             * interpolations are reversed.
             */
            enabled: true,
            displayColors: false,
            backgroundColor:
              this.theme === "light"
                ? StyleValues.color_baseBackground_themeDark
                : StyleValues.color_baseBackground_themeLight,
            bodyFont: { size: 14, weight: "bold" },
            titleFont: "{weight: 'normal'}",
            titleColor: this.theme === "light" ? StyleValues.color_text_themeDark : StyleValues.color_text_themeLight,
            footerFont: "{weight: 'normal'}",
            footerColor: this.theme === "light" ? StyleValues.color_text_themeDark : StyleValues.color_text_themeLight,
            callbacks: {
              title: function title(tooltipItems) {
                let tooltipItem = tooltipItems != null && tooltipItems instanceof Array ? tooltipItems[0] : null;
                let time = null;
                let dataset = tooltipItem.dataset;
                if (dataset) {
                  const point = dataset.data[tooltipItem.dataIndex];
                  if (point) {
                    time = point.timeHHMM;
                  }
                }
                return time;
              },
              label: function oeePointLabel(tooltipItem) {
                if (tooltipItem) {
                  let dataset = tooltipItem.dataset;
                  if (dataset) {
                    const point = dataset.data[tooltipItem.dataIndex];
                    if (point) {
                      const oeePrefix = i18n.t("tiles.oee");
                      return `${oeePrefix}: ${point.uncappedOee.toFixed(1)}%`;
                    }
                  }
                  return tooltipItem.parsed.y.toFixed(1) + "%";
                }
                return "";
              },
              labelTextColor: function oeePointColor(tooltipItem) {
                if (tooltipItem) {
                  let dataset = tooltipItem.dataset;
                  if (dataset) {
                    const point = dataset.data[tooltipItem.dataIndex];
                    if (point) {
                      const oee = point.uncappedOee;
                      if (point.oeeTarget) {
                        const diff = point.oeeTarget - oee;
                        // Green if above or equal to target
                        if (diff <= 0)
                          return this.theme === "light" ? StyleValues.successLight : StyleValues.successDark;
                        // Red if below target by at least 5%
                        if (diff > 5.0) return this.theme === "light" ? StyleValues.errorLight : StyleValues.errorDark;
                        // Yellow if below target, but margin is under 5%
                        return this.theme === "light" ? StyleValues.warningLight : StyleValues.warningDark;
                      }
                    }
                  }
                }
                // Default is green
                return this.theme === "light" ? StyleValues.successLight : StyleValues.successDark;
              },
              footer: function oeePointFooter(tooltipItems) {
                let tooltipItem = tooltipItems != null && tooltipItems instanceof Array ? tooltipItems[0] : null;
                if (tooltipItem) {
                  let dataset = tooltipItem.dataset;
                  if (dataset) {
                    const point = dataset.data[tooltipItem.dataIndex];
                    if (point) {
                      const availabilityPrefix = i18n.t("dashboard.productionGraph.tooltip.availability");
                      const availability = `${availabilityPrefix}: ${point.availability.toFixed(0)}%`;
                      const performancePrefix = i18n.t("dashboard.productionGraph.tooltip.performance");
                      const performance = `${performancePrefix}: ${point.performance.toFixed(0)}%`;
                      const qualityPrefix = i18n.t("dashboard.productionGraph.tooltip.quality");
                      const quality = `${qualityPrefix}: ${point.quality.toFixed(0)}%`;
                      const quantityPrefix = i18n.t("dashboard.productionGraph.tooltip.quantity");
                      const quantity = `${quantityPrefix}: ${point.totalQuantity.toFixed(0)}`;
                      return `${availability}, ${performance}, ${quality}, ${quantity}`;
                    }
                  }
                }
                return "";
              },
            },
          },
        },
      };
    },
    oeeChartData() {
      if (this.activeFactory) {
        const graphLineColor =
          this.theme === "light" ? StyleValues.color_text_themeLight : StyleValues.color_text_themeDark;
        let index = 0;
        let points = this.oeeGraph.curveData.map((val) => {
          let oeeSources = this.oeeGraph.oeeSources[index];
          return {
            x: index++,
            y: this.capOeeValue(val),
            timeHHMM: oeeSources != null ? TimeUtils.toTimeHHMM(oeeSources.timestamp - this.oeeGraph.interval * 1000, this.activeFactory.timezone) + " - " + TimeUtils.toTimeHHMM(oeeSources.timestamp, this.activeFactory.timezone) : "",
            uncappedOee: val,
            oeeTarget: this.oeeGraph.target,
            availability: oeeSources != null ? oeeSources.availability : null,
            performance: oeeSources != null ? oeeSources.performance : null,
            quality: oeeSources != null ? oeeSources.quality : null,
            totalQuantity: oeeSources != null ? oeeSources.totalQuantity : null,
          };
        });
        return {
          labels: this.oeeGraph.labels,
          datasets: [
            {
              order: 0,
              pointRadius: 0,
              pointHitRadius: 10,
              pointHoverBackgroundColor:
                this.theme === "light" ? StyleValues.color_errorLight : StyleValues.color_errorDark,
              borderColor: graphLineColor,
              borderWidth: 2,
              backgroundColor: "transparent",
              tension: 0,
              data: points,
            },
          ],
        };
      } else {
        return {
          labels: this.oeeGraph.labels,
          datasets: [],
        };
      }
    },
    oeeGraphWidth() {
      const intervalAmount = this.oeeGraph.curveData.length - 1;
      return ((intervalAmount * this.oeeGraph.interval) / this.oeeGraph.coverageDuration) * 100 + "%";
    },
    oeeGraphOffset() {
      return (this.oeeGraph.offset / this.oeeGraph.coverageDuration) * 100 + "%";
    },
    oeeGraphTargetTop() {
      return 100 - (this.oeeGraph.target / 125) * 100 + "%";
    },
  },
  methods: {
    capOeeValue(val) {
      if (val == null) return null;
      return Math.min(val, this.oeeChartMaxValue);
    },
  },
};
</script>

<style lang="scss" scoped>
.oee-graph-container {
  $containerDefaultHeight: 40px;

  flex: 1 0 auto;
  position: relative;
  overflow: hidden;
  height: ($containerDefaultHeight * 2);
  margin-top: 10px;
  background-color: var(--color-control-background-theme);

  @media ($wx-isNotMobile) {
    height: $containerDefaultHeight;
  }

  .canvas-container {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;

    ::v-deep canvas {
      height: 100%;
      width: 100%;
    }
  }
  .x-axe-target-line {
    position: absolute;
    width: 100%;
    left: 0;
    right: 0;
    padding-right: 4px;
    text-align: right;
    color: var(--color-error);
    border-top: 2px dashed currentColor;
  }
}
</style>
