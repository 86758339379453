<template>
  <wx-btn-icon
    v-if="!giveawayViewToggle"
    :wxid="$options.name"
    :title="$t('tiles.selectorMenu.btnHint')"
    :x-small="isMobile"
    :small="!isMobile"
    @click="openMenu"
    class="activator-btn"
    text
  >
    <v-icon>mdi-dots-vertical</v-icon>
  </wx-btn-icon>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import WxBtnIcon from "@/components/ui/WxBtnIcon";

export default {
  name: "GroupedTileSelectorMenu",
  components: { WxBtnIcon },
  props: {
    tileIndex: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    ...mapGetters("dashboard", ["giveawayViewToggle"]),
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
  },
  methods: {
    ...mapActions("tiles", ["setIsMenuOpen", "setSelectedTileIndex"]),
    openMenu() {
      this.setSelectedTileIndex(this.tileIndex);
      this.setIsMenuOpen(true);
    },
  },
};
</script>

<style lang="scss" scoped>
// Styles
.activator-btn {
  &.v-btn {
    position: absolute;
    z-index: 1;
    top: var(--box-padding-dashboard);
    right: var(--box-padding-dashboard);
    min-width: 0 !important;
    padding: 0 !important;
  }
}
</style>
