<template>
  <v-dialog
    :wxid="$options.name"
    :value="value"
    @input="$emit('input', $event)"
    max-width="var(--modal-window-narrow-width)"
  >
    <v-card tag="article" :wxid="$options.name" class="wx-confirmation-dialog-card">
      <v-card-title tag="header">
        <v-icon class="mb-3 mb-md-4 mb-xl-6">mdi-access-point-off</v-icon>
        <h2 class="heading">
          {{ $t("connectionIssues.cardTitle") }}
        </h2>
        <p>
          {{ $t("connectionIssues.checkWifi") }}
        </p>
      </v-card-title>
      <v-card-text>
        <v-expansion-panels accordion>
          <v-expansion-panel class="transparent">
            <v-expansion-panel-header />
            <v-expansion-panel-content>
              <p>{{ $t("connectionIssues.ifWifiIsGood") }}</p>
              <ol class="step-list">
                <li>{{ $t("connectionIssues.step1") }}</li>
                <li>{{ $t("connectionIssues.step2") }}</li>
                <li>{{ $t("connectionIssues.step3") }}</li>
                <li>{{ $t("connectionIssues.step4") }}</li>
              </ol>

              <p class="mb-0 pt-3 pt-md-5">
                <strong>
                  {{ $t("connectionIssues.assistance") }}
                  <a :href="'mailto:' + supportEmail">{{ supportEmail }}</a>
                </strong>
              </p>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card-text>
      <v-card-actions tag="footer" class="d-flex justify-center">
        <wx-btn-standard color="primary" class="flex-grow-1" @click="close" large>
          {{ $t("common.understood") }}
        </wx-btn-standard>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import WxBtnStandard from "@/components/ui/WxBtnStandard";

export default {
  name: "TileDisconnectedDialog",
  components: {
    WxBtnStandard,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      supportEmail: process.env.VUE_APP_SUPPORT_EMAIL,
    };
  },
  methods: {
    close() {
      this.$emit("closeDialog");
    },
  },
};
</script>
<style lang="scss" scoped>
.wx-confirmation-dialog-card {
  &.v-card {
    position: relative;
    max-width: var(--modal-window-medium-width);
    padding: var(--dialog-padding);
    background-color: var(--color-element-layer2);
    border-radius: var(--border-radius-lg);
    opacity: 1;

    ::v-deep .v-icon {
      font-size: var(--font-size-h1);
    }
    .v-card__text {
      font-size: var(--font-size-norm);
    }
    .heading,
    p {
      text-align: center;
    }

    &,
    .v-icon,
    .v-card__text,
    .v-card__actions,
    .v-expansion-panel-content {
      color: var(--color-text-theme);
    }

    .v-card {
      // Card Header
      &__title {
        flex-direction: column;
        justify-content: center;
        word-break: break-word;

        p {
          margin-bottom: 0;
        }
      }
    }

    // if expansionPanel is enable
    .v-expansion-panel {
      max-width: var(--modal-window-narrow-width);

      &::before {
        box-shadow: none;
        display: none;
      }

      ::v-deep .v-expansion-panel-header {
        flex-direction: column;
        justify-content: center;
        padding-top: 0;
        padding-bottom: 1em;
        border-bottom: 1px solid var(--color-border-theme);

        &__icon {
          margin-left: 0;

          .v-icon {
            color: var(--color-text-theme);
            border-radius: 50%;
            background-color: var(--color-border-theme);
          }
        }
      }
      .v-expansion-panel-content {
        padding-top: 1em;
        opacity: 0.9;

        p {
          text-align: left;
        }
      }
    }
  }
}

.step-list {
  list-style-type: decimal;
  padding-left: 1.7rem;

  li {
    margin-bottom: var(--dialog-small-padding);
  }
}
</style>
