<template>
  <v-card
    :wxid="$options.name"
    :class="{ disabled: isTileDisabled, unfocused: isTileUnfocused }"
    class="tile product-quantity"
    tag="section"
    sf="1"
  >
    <grouped-tile-selector-menu :tile-index="tileIndex" />
    <div class="tile-inner">
      <h3 :title="title" class="primary-title">
        {{ title }}
        <v-icon v-if="!isTileAuthorized()">mdi-lock</v-icon>
      </h3>
      <h2 class="primary-value">{{ primaryValue }}</h2>
      <p v-if="noConversionFound" class="value-sub-text__unit font-italic">{{ $t("tiles.noConversionNote") }}</p>
      <p v-else class="value-sub-text__unit">{{ valueUnitLabel }}</p>
    </div>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import ProductionUnitService from "@/components/productionunit/ProductionUnitService";
import GroupedTileSelectorMenu from "@/components/dashboard/tiles/GroupedTileSelectorMenu";
import TileBase from "@/components/dashboard/tiles/TileBase";
import Tiles from "@/components/Tiles";
import Helpers, { dash } from "@/helpers";
import TileService from "@/components/dashboard/tiles/TileService";
import { getUnitName } from "@/components/dashboard/tileselectormenu/TileHelper";
import UnitConversionWarningMixin from "@/components/dashboard/tiles/UnitConversionWarningMixin";

export default {
  name: "TileCurrentProductQuantity",
  extends: TileBase,
  inheritAttrs: false,
  mixins: [UnitConversionWarningMixin],
  components: { GroupedTileSelectorMenu },
  computed: {
    ...mapGetters("dashboard", ["productionUnitConvertedUnit", "currentProductQuantity", "activeProductionUnitId"]),
    ...mapGetters("packages", ["activePuHasRequiredFeature"]),
    ...mapGetters("tiles", ["selectedTileIndex"]),
    ...mapGetters("user", ["preferences"]),
    isTileUnfocused() {
      return this.selectedTileIndex !== null && this.tileIndex !== this.selectedTileIndex;
    },
    isTileDisabled() {
      return !this.isTileAuthorized();
    },
    unit() {
      const tile = TileService.getDashboardTile(this.activeProductionUnitId, this.tileIndex, this.preferences);
      return tile?.config?.product_unit ?? null;
    },
    valueUnitLabel() {
      const unitName = this.unit
        ? getUnitName(this.unit).name
        : ProductionUnitService.getUnitName(this.productionUnitConvertedUnit);
      if (this.productionUnitConvertedUnit && this.isTileAuthorized()) {
        return unitName;
      } else {
        return "";
      }
    },
    value() {
      return this.currentProductQuantity(this.unit);
    },
    title() {
      return this.$t("tiles.currentProductQty");
    },
    primaryValue() {
      return this.value && this.isTileAuthorized() ? Helpers.round(this.currentProductQuantity(this.unit), 1) : dash;
    },
  },
  methods: {
    isTileAuthorized() {
      return this.activePuHasRequiredFeature(Tiles.currentProductQuantity.requiredFeature);
    },
  },
};
</script>
