<template>
  <v-dialog v-model="show" max-width="600" persistent>
    <v-card class="pa-5">
      <v-card-title>
        <v-icon class="mr-2">mdi-alert-circle-outline</v-icon>
        {{ $t("justification.confirmDialog.title") }}
      </v-card-title>
      <v-card-text class="pl-14" v-html="confirmHtml()" />
      <v-card-actions>
        <v-spacer />
        <wx-btn-standard color="secondary" @click="cancel">{{ $t("common.cancel") }}</wx-btn-standard>
        <wx-btn-standard color="primary" @click="confirm">{{ $t("common.confirm") }}</wx-btn-standard>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import WxBtnStandard from "@/components/ui/WxBtnStandard.vue";

export default {
  name: "SubmitOopJustificationDialog",
  components: { WxBtnStandard },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    confirm() {
      this.$emit("confirm");
    },
    cancel() {
      this.$emit("cancel");
    },
    confirmHtml() {
      return this.$t("justification.confirmDialog.content");
    },
  },
};
</script>

<style lang="scss" scoped>
.v-card {
  background-color: var(--color-base-background);
}
</style>