<template>
  <v-dialog v-model="modalDialog" :wxid="$options.name" fullscreen>
    <article class="v-dialog__content">
      <header>
        <v-btn icon large @click="closeModalDialog" :title="$t('common.dialogFormCancelHoverTitle')" class="close-btn">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <h2 class="mb-2 md-8 wx-typo-h1">{{ $t("dashboard.productionRun.newTitle") }}</h2>
      </header>

      <production-run-form
        v-if="modalDialog"
        ref="productionRunForm"
        @close="closeModalDialog"
        @submit="submitProductionRun"
      />
    </article>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ProductionRunForm from "@/components/dashboard/productionRun/ProductionRunForm.vue";
import ErrorHandling from "@/components/ErrorHandling";
import i18n from "@/i18n";

export default {
  name: "CreateProductionRunDialog",
  components: {
    ProductionRunForm,
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    btnCssClass: {
      type: String,
      default: null,
    },
  },
  model: {
    prop: "modelValue",
    event: "update:modelValue",
  },
  computed: {
    ...mapGetters("user", ["isPresenter"]),
    modalDialog: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
  methods: {
    ...mapActions("dashboard", ["createProductionRun"]),
    ...mapActions("operation", ["showOperationError"]),
    submitProductionRun(submission) {
      const formattedSubmission = {
        sku: submission.sku,
        work_order_number: submission.workOrder,
        lot_number: submission.lotNumber,
        number_of_employees: submission.numberOfEmployees,
        planned_quantity: submission.plannedQuantity,
        effective_start_time: submission.startTime,
        forwarded_production_unit_ids: submission.forwardedProductionUnitIdsAsCsv,
        forwarded_production_unit_group: submission.forwardedProductionUnitGroup,
      };
      this.createProductionRun(formattedSubmission)
        .then(this.closeModalDialog)
        .catch((error) => {
          this.showOperationError(
            ErrorHandling.buildErrorsMessages(error.response, (code) => {
              if (code === "DSH_PRODRUN_POST_10009") {
                return i18n.t("dashboard.productionRun.errors.conflictStartTime");
              }
              if (code === "DSH_PRODRUN_POST_1021") {
                return i18n.t("dashboard.productionRun.errors.productNotAssociated", { sku: submission.sku });
              }
              return i18n.t("common.errors.default", { code: code });
            }),
          );
        });
    },
    closeModalDialog() {
      this.$refs.productionRunForm.reset();
      this.modalDialog = false;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-dialog {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--color-base-theme);

  &__content {
    display: flex;
    align-items: center;
    flex-flow: column nowrap;
    position: initial;
    left: auto;
    width: 100%;
    max-width: 600px;
    padding-inline: var(--grid-gutter);
    pointer-events: auto;

    header {
      padding-top: var(--grid-gutter);

      .close-btn {
        position: fixed;
        z-index: 1; // hover fields
        top: var(--dialog-close-offset);
        right: var(--dialog-close-offset);
      }
    }
    .v-form {
      width: 100%;

      fieldset {
        border: none;

        &.form-footer-actions {
          display: flex;
          justify-content: flex-end;
          column-gap: var(--btn-inline-margin);
          padding-bottom: var(--grid-gutter);
        }
      }

      // Responsive Columns
      .row {
        margin-top: 0;
        margin-bottom: 0;
        .field-col {
          padding-top: 0;
          padding-bottom: 0;
        }
      }
    }
  }
}
</style>
